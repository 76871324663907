import React, { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../../../../components/Buttons/Button";
import Table from "../../../../../../components/table/Table";
import Pagination from "../../../../../../components/pagination/Pagination";
import Loader from "../../../../../../components/loader/Loader";
import BlockChainImg from "../../../../../../assets/Blockchain.png";

const ViewCourses = ({
  courses,
  isLoading,
  search,
  setSearch,
  perPage,
  setPerPage,
  currentPage,
  setCurrentPage,
  refetch,
}) => {
  const navigate = useNavigate();

  let columns = useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) =>
          index +
          1 +
          ((courses?.meta?.page || 1) - 1) *
            (courses?.meta?.perPage || perPage),
      },
      {
        title: "Image",
        selector: "imagePath",
        key: "imagePath",
        render: ({ item }) => {
          return (
            <img
              className="w-[40px] rounded-lg object-cover h-[40px] ml-2"
              alt=""
              src={item}
            />
          );
        },
      },
      {
        title: "Course Name",
        selector: "name",
        key: "name",
      },
      {
        title: "Status",
        selector: "status",
        key: "status",
        render: ({ item }) => {
          if (item === "published") {
            return <span className="text-green-500">{item}</span>;
          } else return <span className="text-yellow-500">{item}</span>;
        },
      },
      {
        title: "Category",
        selector: "category",
        key: "category",
        render: ({ item }) => {
          return <span className="">{item?.name}</span>;
        },
      },
      {
        title: "Duration",
        selector: "duration",
        key: "duration",
        render: ({ item }) => {
          return <span className="">{item}</span>;
        },
      },
      {
        title: "Course Code",
        selector: "courseCode",
        key: "courseCode",
        render: ({ item }) => {
          return <span className="">{item || "-------"}</span>;
        },
      },
      {
        title: "Credits",
        selector: "credit",
        key: "credit",
        render: ({ item }) => {
          return <span className="">{item || "---------"}</span>;
        },
      },
      {
        title: "Branded",
        selector: "isBranded",
        key: "isBranded",
        render: ({ item }) => {
          return (
            <span
              className={`bi bi-check-circle ml-3 ${
                item === true ? "text-green-500" : "text-red-500"
              }`}
            >
              {item}
            </span>
          );
        },
      },
      {
        title: "Date Created",
        selector: "createdAt",
        key: "createdAt",
        render: ({ item }) => {
          return (
            <span className="">{`${new Date(item).toLocaleDateString(
              "en-ng"
            )}`}</span>
          );
        },
      },
      {
        title: "Date Updated",
        selector: "updatedAt",
        key: "updatedAt",
        render: ({ item }) => {
          return (
            <span className="">{`${new Date(item).toLocaleDateString(
              "en-ng"
            )}`}</span>
          );
        },
      },
      {
        title: "Action",
        selector: null,
        key: "actions",
        render: ({ row }) => {
          return (
            <Link
              className="bi bi-pencil-square text-blue-500 text-lg"
              to={`/admin/course/edit/${row?.id}`}
              onClick={() => {
                sessionStorage.setItem("active-course", JSON.stringify(row));
              }}
            />
          );
        },
      },
      /*{
        title: "Actions",
        selector: null,
        key: "actions",
        render: ({ row }) => {
          return (
            <VerticalMenu isBackground width={'min-w-[120px]'}> 
              <div>                       
                  {
                    !row?.status ? 
                      <button 
                        onClick={ () => toggleAffiliate(row?.user_id) } 
                        className='text-md py-2 text-center bg-green-100 text-green-500 w-[120px] block'
                      >
                        Enable Affiliate
                      </button>   
                     :
                     <button 
                        onClick={ () => toggleAffiliate(row?.user_id) } 
                        className='text-md py-2 text-center bg-red-100 text-red-500 w-[120px] block'
                      >
                        Disable Affiliate
                      </button>   
                  }                                          
              </div>
            </VerticalMenu>
          );
        },
      },*/
    ],
    [1, 20, 20]
  );

  const tableProps = { isLoading: false, columns, data: courses?.data };

  return (
    <div>
      <header className="flex justify-between mt-3 items-center px-2">
        <h4 className="text-sm">View Courses</h4>
        <div className="mt-2">
          <Button
            text={"Create New Course"}
            width={"w-[170px]"}
            bgColor={"bg-primary"}
            onClick={() => {
              navigate("/admin/course/create-course");
            }}
          />
        </div>
      </header>
      <section className="bg-lightBlue shadow flex items-center justify-between mx-2 px-2 py-2 mt-3">
        <h3 className="text-xs ">Total Courses ({courses?.meta?.total})</h3>
        <div className="w-[300px]">
          <input
            type="text"
            className="input-md"
            value={search}
            placeholder="Search e.g how to learn..."
            onChange={(e) => {
              setCurrentPage(1);
              setSearch(e.target.value);
            }}
          />
        </div>
      </section>
      <section className="mt-0 px-3">
        {!isLoading ? (
          !courses?.data?.length ? (
            <div className="w-full mt-12">
              <img
                src={BlockChainImg}
                alt="no result"
                className="w-[260px] block mx-auto h-[190px]"
              />
              <p className="text-center font-semibold text-xs mt-3 ml-3">
                No any course found.
              </p>
            </div>
          ) : (
            <Table {...tableProps} />
          )
        ) : (
          <Loader />
        )}
      </section>
      <section className={`mt-5 px-3`}>
        <Pagination
          data={courses?.meta}
          perPage={perPage}
          pageNo={currentPage}
          setPageNo={setCurrentPage}
          setPerPage={setPerPage}
        />
      </section>
    </div>
  );
};

export default ViewCourses;
