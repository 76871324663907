import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { learners } from "../../../../../api/learners";
import { convertDate } from "../../../../helpers";
import { LearnersContext } from "../../../../../context/learnersContext";

const DownloadData = ({ pageType }) => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const { selectedCohort, selectedPath, selectedCourse } =
    useContext(LearnersContext);

  //   console.log(selectedCourse);

  const { mutate: mutateProfile } = useMutation(
    learners.DownloadLearners_Profile,
    {
      onSuccess: (data) => {
        // This will run when the data is successful
        console.log("Fetched mutation:", data);

        // console.log("Fetched mutat:", mutation);
        toast.remove();

        const downloadData = data?.data?.map((data) => {
          return {
            name: data?.user?.name,
            lastLogin: data?.lastLogin,
            email: data?.user?.email,
            phoneNumber: data?.user?.phoneNumber,
            createdAt: convertDate(data?.user?.createdAt),
            emailVerifiedAt: convertDate(data?.user?.emailVerifiedAt),
            gender: data?.user?.gender,
            organization: data?.user?.organization?.name,
          };
        });

        if (!downloadData?.length) return toast.error("No data to download...");

        const worksheet = XLSX.utils.json_to_sheet(downloadData);

        const csvOutput = XLSX.utils.sheet_to_csv(worksheet);

        const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });

        saveAs(blob, "data.csv");
        toast.success("Downloaded successfully...");
      },
      onError: (error) => {
        console.log("Error fetching data:", error);
        toast.remove();
        toast.error("Unable to download, pls try again later...");
        console.log({ error });
      },
      onMutate: () => {
        // console.log("processing");
        toast.loading("Processing");
        // console.log({ error });
      },
    }
  );

  const { mutate: mutateCohort } = useMutation(
    learners.DownloadLearners_Cohort,
    {
      onSuccess: (data) => {
        // This will run when the data is successful
        // console.log("Fetched mutation:", data);

        // console.log("Fetched mutat:", mutation);
        toast.remove();

        const downloadData = data?.data?.map((data) => {
          return {
            CohortName: data?.cohortName,
            Name: data?.name,
            Email: data?.email,
            EnrolledAt: data?.enrolledAt,
            Status: data?.status,
          };
        });

        if (!downloadData?.length) return toast.error("No data to download...");

        const worksheet = XLSX.utils.json_to_sheet(downloadData);

        const csvOutput = XLSX.utils.sheet_to_csv(worksheet);

        const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });

        saveAs(blob, `${selectedCohort?.cohortName}.csv`);
        toast.success("Downloaded successfully...");
      },
      onError: (error) => {
        console.log("Error fetching data:", error);
        toast.remove();
        toast.error("Unable to download, pls try again later...");
        console.log({ error });
      },
      onMutate: () => {
        // console.log("processing");
        toast.loading("Processing");
        // console.log({ error });
      },
    }
  );

  const { mutate: mutatePath } = useMutation(learners.DownloadLearners_Path, {
    onSuccess: (data) => {
      // This will run when the data is successful
      //   console.log("Fetched mutation:", data);

      //   console.log("Fetched mutat:", mutation);
      toast.remove();

      const downloadData = data?.data?.map((data) => {
        // Start with the Name and Email properties
        let result = {
          Name: data?.user?.name,
          Email: data?.user?.email,
        };

        // Iterate over selectedPath?.courses and merge the course objects into result
        selectedPath?.courses?.forEach((path, index) => {
          // Assign each course to the result object
          result[path?.courseName] = `${data?.courses[
            index
          ]?.status?.toUpperCase()}, Score:${
            data?.courses[index]?.overallScore || "-"
          }`;
        });

        return result;
      });

      //   console.log(downloadData);

      if (!downloadData?.length) return toast.error("No data to download...");

      const worksheet = XLSX.utils.json_to_sheet(downloadData);

      const csvOutput = XLSX.utils.sheet_to_csv(worksheet);

      const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });

      saveAs(blob, `${selectedPath?.learningPathName}.csv`);
      toast.success("Downloaded successfully...");
    },
    onError: (error) => {
      console.log("Error fetching data:", error);
      toast.remove();
      toast.error("Unable to download, pls try again later...");
      console.log({ error });
    },
    onMutate: () => {
      // console.log("processing");
      toast.loading("Processing");
      // console.log({ error });
    },
  });

  const { mutate: mutateCourse } = useMutation(
    learners.DownloadLearners_Course,
    {
      onSuccess: (data) => {
        // This will run when the data is successful
        // console.log("Fetched mutation:", data);

        //   console.log("Fetched mutat:", mutation);
        toast.remove();

        const downloadData = data?.data?.map((data) => {
          // Start with the Name and Email properties
          let result = {
            Name: data?.name,
            Email: data?.email,
          };

          // Iterate over selectedPath?.courses and merge the course objects into result
          selectedCourse?.modules?.forEach((course, index) => {
            // Assign each course to the result object
            result[course?.module?.name] = `${data?.modules[
              index
            ]?.status?.toUpperCase()}, Score:${
              data?.modules[index]?.overallScore || "-"
            }`;
          });

          return result;
        });

        // console.log(downloadData);

        if (!downloadData?.length) return toast.error("No data to download...");

        const worksheet = XLSX.utils.json_to_sheet(downloadData);

        const csvOutput = XLSX.utils.sheet_to_csv(worksheet);

        const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });

        saveAs(blob, `${selectedCourse?.courseName}.csv`);
        toast.success("Downloaded successfully...");
      },
      onError: (error) => {
        console.log("Error fetching data:", error);
        toast.remove();
        toast.error("Unable to download, pls try again later...");
        console.log({ error });
      },
      onMutate: () => {
        // console.log("processing");
        toast.loading("Processing");
        // console.log({ error });
      },
    }
  );

  const handleDownload = () => {
    switch (pageType) {
      case "profile":
        mutateProfile({ orgId: user?.org?.id });
        break;
      case "cohort":
        mutateCohort({ cohortId: selectedCohort?.cohortId });
        break;
      case "path":
        mutatePath({
          cohortId: selectedCohort?.cohortId,
          pathId: selectedPath?.learningPathId,
        });
        break;
      case "course":
        mutateCourse({
          cohortId: selectedCohort?.cohortId,
          pathId: selectedPath?.learningPathId,
          courseId: selectedCourse?.courseId,
        });
        break;
      default:
        toast.error("Invalid page type");
        break;
    }
  };

  return (
    <button
      onClick={handleDownload}
      className="py-[12px] cursor-pointer mb-3 w-[200px] outline-none border border-solid border-darkBlue bg-darkBlue flex items-center justify-center gap-2 text-white focus:ring-0 text-xs rounded-md px-2"
    >
      <FontAwesomeIcon icon={faCloudArrowDown} />
      <p> Download Data</p>
    </button>
  );
};

export default DownloadData;
