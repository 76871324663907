import React, { useState } from "react";
import PathInfo from "./components/PathInfo";
import { Form, Formik } from "formik";
import { useEffect } from "react";
import { Tab, initTE, } from "tw-elements";
import PathContent from "./components/PathContent";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import ProgramContent from "./components/PogramContent";

const EditPath = () => {

  const active_path = JSON.parse(sessionStorage.getItem("active-path"));
  const [activeScreen, setActiveScreen] = useState(1);
  const [preview, setPreview] = useState(active_path?.imagePath || null);
  const [isCreated,setIsCreated] = useState(false);
  const navigate = useNavigate()

  useEffect( () => initTE({ Tab }) , [] )

  const initialValues = {
    c_title: active_path?.name || "",
    c_des: active_path?.description || "",
    c_title_fr: active_path?.languages[1]?.name || "",
    c_des_fr: active_path?.languages[1]?.description|| "",
    c_title_pr: active_path?.languages[2]?.name || "",
    c_des_pr: active_path?.languages[2]?.description || "",
    image: active_path?.imagePath || "",
   // c_point: "",
    start_date: active_path?.startDate || "",
    end_date: active_path?.endDate || "",
    //c_credit: "",
    c_type: active_path?.type === 'program' ? "program" : "learning-path",
    status: active_path?.status === 'published' ? true : false
  };

  const validate = (values) => {
    const errors = {};
    // Existing validations
    if (!values?.c_title) errors.c_title = `Path title is required`;
    else if (!values?.c_des) errors.c_des = `Path description is required`;
    else if (!values?.c_title_fr) errors.c_title_fr = `French Path description is required`;
    else if (!values?.c_title_pr) errors.c_title_pr = `Portuguese Path description is required`;
    else if (!values?.c_des_fr) errors.c_des_fr = `French Path description is required`;
    else if (!values?.c_des_pr) errors.c_des_pr = ` Portuguese Path description is required`;
    //else if (!values?.c_credit) errors.c_credit = `Credit is required`;
    //else if (!values?.c_point) errors.c_point = `Points is required`;
    else if (!values?.start_date) errors.start_date = `Start Date is required`;
    else if (!values?.end_date) errors.end_date = `End Date is required`;
    else if (!values?.c_type) errors.c_type = `Type is required`;

    // Additional validation: Start date cannot be after the end date
    const startDate = new Date(values.start_date);
    const endDate = new Date(values.end_date);

    if (startDate > endDate) {
      errors.start_date = `Start Date cannot be after End Date`;
      errors.end_date = `End Date cannot be before Start Date`;
    }
    return errors;
  };

  const createPath = (values, actions) => {
    actions.setSubmitting(false);
  };

  const step1Validated = (values) =>
    values.c_title &&
    values.c_des &&
   // values.c_point &&
   // values.c_credit &&
    values.start_date &&
    values.end_date &&
    values.c_type;

  return (
    <div className="bg-lightBlue min-h-[90vh] pl-8 pt-2">
      <header className="mt-3 pr-3 flex justify-between items-center">
            <h3 className="text-md font-semibold mt-3 bi bi-pencil-square">
              &nbsp;Edit {active_path?.type === 'program' ? 'Program' : 'Learning Path'}
          </h3>
          <Link to={'/admin/learning-path?refresh=1'} className="text-white bg-primary text-xs rounded-full py-2 px-5 bi bi-arrow-left">
             &nbsp; Back
          </Link>
      </header>
      <section>
      <ul
        className="mb-5 mt-2 flex list-none flex-row flex-wrap border-b border-b-2 border-gray-200 pl-0"
        role="tablist"
        data-te-nav-ref
       >
              <li role="presentation">
                    <a
                      href="#tabs-home"
                      className="my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 py-1 text-[10px] font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 data-[te-nav-active]:bg-neutral-200  focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
                      data-te-toggle="pill"
                      data-te-target="#tabs-home"
                      data-te-nav-active
                      role="tab"
                      aria-controls="tabs-home"
                      aria-selected="true"
                      > {active_path?.type === 'program' ? 'Program' : 'Learning Path'} Information </a>
                  </li>
                  <li role="presentation">
                    <a
                      href="#tabs-profile"
                      className="my-2 block data-[te-nav-active]:bg-neutral-200 border-x-0 border-b-2 border-t-0 border-transparent px-7 py-1 text-[10px]  font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
                      data-te-toggle="pill"
                      data-te-target="#tabs-profile"
                      role="tab"
                      aria-controls="tabs-profile"
                      aria-selected="false"
                      > {active_path?.type === 'program' ? 'Program' : 'Learning Path'} Content</a>
                  </li>
        </ul>
        <div className="mb-6">
                <div
                  className="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                  id="tabs-home"
                  role="tabpanel"
                  aria-labelledby="tabs-home-tab"
                  data-te-tab-active
                >
                 <section>
                 <Formik
                    initialValues={initialValues}
                    validate={validate}
                    enableReinitialize={true}
                    onSubmit={createPath}
                  >
                      {({ values, setFieldError, setFieldValue, isValid }) => (
                        <Form>
                            <PathInfo
                              values={values}
                              setFieldValue={setFieldValue}
                              logoPreview={preview}
                              setLogoPreview={setPreview}
                              step1Validated={step1Validated(values)}
                              setActiveScreen={setActiveScreen}
                              setIsCreated={setIsCreated}
                            />
                          <button
                            type="submit"
                            id="create-path-btn"
                            className={`hidden`}
                            disabled={validate}
                          ></button>
                        </Form>
                      )}
                    </Formik>
                 </section>
                </div>
                <div
                  className="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                  id="tabs-profile"
                  role="tabpanel"
                  aria-labelledby="tabs-profile-tab">
                      {active_path?.type === 'program' ? <ProgramContent /> : <PathContent /> }
                  </div>
          </div>
      </section>
    </div>
  );
};

export default EditPath;
