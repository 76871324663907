import React, { useState } from 'react';

const HorizontalTab = ({ tabsData }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <div className='rounded-sm shadow-xxl bg-white p-4 tracking-normal '>
      <div className='flex space-x-4 w-full border-b border-[#EAEAEA] px-2 rounded-lg'>
        {tabsData?.map((tab, idx) => (
          <button
          type='button'
            key={idx}
            className={`py-2 transition-colors duration-300 text-base ${
              idx === activeTabIndex
                ? 'text-[#ED6F1E] font-semibold  border-b-2 border-[#FF9100]'
                : 'border-transparent hover:border-gray-200'
            }`}
            onClick={() => setActiveTabIndex(idx)}
          >
            {tab?.label}
          </button>
        ))}
      </div>
      <div className='py-4'>
        <span>{tabsData[activeTabIndex]?.content}</span>
      </div>
    </div>
  );
};

export default HorizontalTab;
