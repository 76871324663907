import React from 'react'
import Button from '../../../../../../../components/Buttons/Button'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import CustomDropdown from './../../../../../../../components/dropdown/CustomDropdown';
import Switch from 'react-switch'
import { api } from '../../../../../../../api/quiz-and-sport';
import { useMutation, useQuery } from 'react-query';
import { api as teamsApi } from '../../../../../../../api/teams';
import toast from 'react-hot-toast';
import { errorResponse } from '../../../../../../../components/utils/errorResponse';
import { useNavigate } from 'react-router-dom';

const CreateSport = ({activeSport, setActiveSport}) => {

    const key = "get quiz dropdown"
    const orgId =  JSON.parse(sessionStorage.getItem('user'))?.org?.id
    const navigate = useNavigate()
    const { data: quiz, isLoading: quizLoading, } = useQuery(
        [key, { perPage: 10000, currentPage: 1, search: null, orgId }],
        ({queryKey}) => { 
            return api.getQuiz(queryKey[1]);
        },
        {
            cacheTime: 100,
            staleTime: 100,
            select: response => response?.data
      });

      const { data: teams, isLoading: teamsLoading, } = useQuery(
        [ 'get teams dropdown', { perPage: 10000, currentPage: 1, search: null, orgId }],
        ({queryKey}) => { 
            return teamsApi.getTeams(queryKey[1]);
        },
        {
            cacheTime: 100,
            staleTime: 100,
            select: response => response?.data
      });

    const createSportMutation = useMutation(
         activeSport === null ? api.createSports : api.updateSports , {
        onSuccess: res => {
          toast.remove()
          toast.success(res?.message)
          navigate(`/admin/learners/quiz-and-sports?tab=sports`)
        },
        onMutate: () => {
            toast.loading('Loading...')
        },
        onError: error => errorResponse(error)
    })

    const initialValues = {
         orgQuizId: activeSport === null ? "" : activeSport?.orgQuizId,
         name: activeSport === null ? "" : activeSport?.name,
         teams: activeSport === null ? [] : activeSport?.sportTeams?.map( sport => {
              return { label: sport?.team?.name, value: sport?.team?.id }
         }),
         status: 'active'
    }

    const validate = (values) => {
        const errors = {};
        if(!values?.name) errors.name = `Error! sport name is required`
        else if(!values?.orgQuizId) errors.orgQuizId = `Error! select quiz.`
        else if(!values?.teams?.length) errors.teams = `Error! select atleast one team.`
        return errors;
    }

    const createSport = (values, actions) => {
        actions.setSubmitting(false);
        const payload = {...values, teams: values?.teams?.map( team => team?.value )}
        createSportMutation.mutate( 
            activeSport === null ? { orgId, data: payload } :
            { orgId, data: payload, id: activeSport?.id }
        )
      }

  return (
    <div className='h-[100vh]'>
        <header className='flex items-center justify-between'>
             <h5 className='text-md font-semibold -mt-2 bi bi-plus-circle'> 
               &nbsp; Create Inter House Sport Team   
             </h5>
             <Button 
               width={'w-[100px]'}
               text={'Back'}
               renderAs='link'
               to={-1}
               bgColor={'bg-secondary'}
             />
        </header>
        <section className='bg-white md:w-[500px] mx-auto mt-5 rounded-xl p-4 shadow-lg'>
        <Formik
                   initialValues={initialValues}
                   validate={validate}
                   enableReinitialize={true}
                   onSubmit={createSport}
             >
            {({ values, setFieldError, setFieldValue, isValid, errors }) => (
                <Form>
                    <div className='w-[90%] mx-auto mt-3'>
                        <label htmlFor="name" className='text-xs block'>
                            Sports Name <span className='text-sm pl-1 text-red-500'>*</span>
                        </label>
                        <Field 
                           style={{border: errors?.name ? '1px solid red' : '1px solid #ddd' }} 
                          className='input-md mt-1 shadow' name='name' 
                          placeholder='Enter inter house sport name'
                        />
                        <p className='text-xs mt-2 text-red-500'>
                            <ErrorMessage name='name'  />
                        </p>
                    </div>
                    <div className='w-[90%] mx-auto mt-3'>
                        <label htmlFor="orgQuizId" className='text-xs block'>
                            Select Quiz <span className='text-sm pl-1 text-red-500'>*</span>
                        </label>
                        <Field 
                           style={{border: errors?.orgQuizId ? '1px solid red' : '1px solid #ddd' }} 
                          className='input-md mt-1 shadow' name='orgQuizId' 
                          as='select'
                        > 
                         { quizLoading ? <option value="">Loading...</option> :
                           quiz?.length ?
                           <>
                              <option value="">Select Quiz...</option>
                              {
                               quiz?.length ? 
                                 quiz?.map( quiz => {
                                    return (
                                        <option key={quiz?.id} value={quiz?.id}>
                                            {quiz?.name}
                                        </option>
                                    )
                                 } )
                              : null
                              }
                           </>
                           : <option value="">No Quiz Available...</option>
                         }
                        </Field>
                        <p className='text-xs mt-2 text-red-500'>
                            <ErrorMessage name='orgQuizId'  />
                        </p>
                    </div>
                    <div className='w-[90%] mx-auto mt-3'>
                        <label htmlFor="orgQuizId" className='text-xs block'>
                            Select Teams <span className='text-sm pl-1 text-red-500'>*</span>
                        </label>
                         <CustomDropdown 
                           options={
                             teamsLoading ? [] : 
                              teams?.length ? 
                               teams?.map( team => {
                                 return { label: team?.team?.name, value: team?.team?.id }
                               })
                              : []
                           }
                           value={values?.teams}
                           multiSelect
                           placeholder={'Select teams to participate...'}
                           width={'w-full'}
                           onChange={ values => {
                              setFieldValue('teams', values)
                           } }
                          />
                        <p className='text-xs mt-2 text-red-500'>
                            <ErrorMessage name='teams'  />
                        </p>
                    </div>
                    <div className='flex text-xs items-center mt-4 ml-[5%]'>
                      Status &nbsp;&nbsp;&nbsp;&nbsp;
                        <Switch
                            height={20}
                            width={40}
                            handleDiameter={18}
                            checked={values?.status === 'active'}
                            onChange={() =>
                               setFieldValue('status', values?.status === 'active' ? 'inactive' : 'active')
                            }
                        />
                    </div>
                    <div className='w-[90%] flex justify-center mx-auto my-3'>
                        <Button
                          type='submit'
                          width={'w-[250px]'}
                          bgColor={'bg-secondary'}
                          text={ activeSport === null ? 'Create Sport Team': 'Edit Sport Team'}
                          onClick={ () => document.querySelector('#create-sport-btn').click() }
                        />
                        <button type='submit' className='hidden' id='create-sport-btn' />
                    </div>
                 </Form>
             )}
         </Formik>
        </section>
    </div>
  )
}

export default CreateSport