import React, { useState } from "react";
import CourseInfo from "./components/CourseInfo";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { Tab, initTE } from "tw-elements";
import CourseContent from "./components/CourseContent";

const EditCourse = ({ setCourses, refetch }) => {
  const activeCourse = JSON.parse(sessionStorage.getItem("active-course"));
  const [preview, setPreview] = useState(activeCourse?.imagePath);
  const [courseId] = useState(activeCourse?.id);
  const [activeScreen, setActiveScreen] = useState(1);

  React.useEffect(() => initTE({ Tab }), []);

  
  const initialValues = {
    c_title_en: activeCourse?.name || "",
    c_des_en: activeCourse?.description || "",
    c_title_fr: activeCourse?.languages[1]?.name || "",
    c_des_fr: activeCourse?.languages[1]?.description|| "",
    c_title_pr: activeCourse?.languages[2]?.name || "",
    c_des_pr: activeCourse?.languages[2]?.description || "",
    image: activeCourse?.imagePath || "",
    c_category: activeCourse?.category?.id || "",
    c_point: activeCourse?.points || "",
    module: "",
    module_point: "",
    time: "",
    c_price: activeCourse?.price || "",
    status: activeCourse?.status === "published",
    c_pre_assessment_type: false,
    c_post_assessment_type: false,
  };

  const validate = (values) => {
    const errors = {};
    if (!values?.c_title_en)
      errors.c_title_en = `Error! Course title is required`;
    else if (!values?.c_title_fr) {
      errors.c_title_fr = `Error! French Course title is required`;
    } else if (!values?.c_title_pr) {
      errors.c_title_pr = `Error! Portuguese Course title is required`;
    } else if (!values?.c_des_en) {
      errors.c_des_en = `Error! Course description is required`;
    } else if (!values?.c_des_fr) {
      errors.c_des_fr = `Error! French Course description is required`;
    } else if (!values?.c_des_pr) {
      errors.c_des_pr = `Error!  Portuguese Course description is required`;
    } else if (!values?.c_category) {
      errors.c_category = `Error! Course category is required`;
    } else if (!values?.c_point) {
      errors.c_point = `Error! Course point is required`;
    } else if (!values?.c_price) {
      errors.c_price = `Error! Course price is required`;
    }
    return errors;
  };

  const createCourse = (values, actions) => {
    actions.setSubmitting(false);
  };

  return (
    <div className="bg-lightBlue pl-8 pt-2 pb-8">
      <h3 className="text-md  flex justify-between font-semibold mt-3">
        <span className="bi bi-pencil-square"> &nbsp; Edit Course </span>
        <Link
          to={-1}
          className="bi bi-arrow-left text-sm mr-5 bg-white py-2 px-5 rounded-full"
        >
          {" "}
          &nbsp; Back
        </Link>
      </h3>
      <ul
        className="mb-5 mt-2 flex list-none flex-row flex-wrap border-b border-b-2 border-gray-200 pl-0"
        role="tablist"
        data-te-nav-ref
      >
        <li role="presentation">
          <a
            href="#tabs-home"
            className="my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 py-1 text-[10px] font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
            data-te-toggle="pill"
            data-te-target="#tabs-home"
            data-te-nav-active
            role="tab"
            aria-controls="tabs-home"
            aria-selected="true"
          >
            {" "}
            Course Information{" "}
          </a>
        </li>
        <li role="presentation">
          <a
            href="#tabs-profile"
            className="my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 py-1 text-[10px]  font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
            data-te-toggle="pill"
            data-te-target="#tabs-profile"
            role="tab"
            aria-controls="tabs-profile"
            aria-selected="false"
          >
            Course Content
          </a>
        </li>
      </ul>
      <div className="mb-6">
        <div
          className="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
          id="tabs-home"
          role="tabpanel"
          aria-labelledby="tabs-home-tab"
          data-te-tab-active
        >
          <section>
            <Formik
              initialValues={initialValues}
              validate={validate}
              enableReinitialize={true}
              onSubmit={createCourse}
            >
              {({ values, setFieldError, setFieldValue, isValid }) => (
                <Form>
                  <CourseInfo
                    values={values}
                    setFieldValue={setFieldValue}
                    logoPreview={preview}
                    setLogoPreview={setPreview}
                    step1Validated={true}
                    courseId={courseId}
                    refetch={refetch}
                  />
                  <button
                    type="submit"
                    id="create-course-btn"
                    className={`hidden`}
                  ></button>
                </Form>
              )}
            </Formik>
          </section>
        </div>
        <div
          className="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
          id="tabs-profile"
          role="tabpanel"
          aria-labelledby="tabs-profile-tab"
        >
          <CourseContent refetch={refetch} courseId={courseId} />
        </div>
      </div>
    </div>
  );
};

export default EditCourse;
