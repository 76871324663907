import React, {useState} from 'react'
import Button from '../../../../../../../../../components/Buttons/Button'
import Editor from '../../../../../../../../../components/Editor'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'
import { errorResponse } from '../../../../../../../../../components/utils/errorResponse'
import { useNavigate } from 'react-router-dom'
import { api } from '../../../../../../../../../api/teams'
import HorizontalTab from "../../../../../../../../../components/Tab/HorizontalTab";

const CohortWelcomeMessage = ({ setCurrentStep, content, setContent, cohortId}) => {
  const [useMultipleLanguages, setUseMultipleLanguages] = useState(false);
  const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id
  const navigate = useNavigate()
  const [frenchContent, setFrenchContent] = useState("")
  const [portugueseContent, setPortugueseContent] = useState("")

  const addInvitationMutation = useMutation(api.addWelcomePage, {
    onSuccess: () => {
        toast.remove()
        toast.success('Cohort Welcome Page Added Successfully!')
        navigate('/admin/learning-path/learners-group/view')
    },
    onError: error => errorResponse(error),
    onMutate: () => {
        toast.loading('Loading...')
    }
  })

  const addMultilinguaWelcomeMessageMutation = useMutation(api.addMultiLanguageWelcomeMessage, {
    onSuccess: () => {
        toast.remove()
        toast.success('Cohort Welcome Page Added Successfully!')
        navigate('/admin/learning-path/learners-group/view')
    },
    onError: error => errorResponse(error),
    onMutate: () => {
        toast.loading('Loading...')
    }
  })

  const addWelcomePage = () => {
        if(!content) toast.error('Error! Add Welcome Page Template')
      else addInvitationMutation.mutate({
        cohortId, orgId, payload: {
          messageText: content          
        }        
     })
  }

  

  const addMultiLanguageWelcomePage = () => {
        if (!content) {
      toast.error('Error! Add Welcome Page Template');
    } else {
      const payload = {        
        languages: [
          {
            language: "english",
            symbol: "en",
            welcomeMessage:content,
            isDefault: true,
          },
          {
            language: "french",
            symbol: "fr",
            welcomeMessage:frenchContent,
            isDefault: false,
          },
          {
            language: "portuguese",
            symbol: "pr",
            welcomeMessage:portugueseContent,
            isDefault: false,
          }
        ],
      };
  
      addMultilinguaWelcomeMessageMutation.mutate({
          orgId,
        cohortId,
        payload,
      });
    }
  };
  

  const tabsData = [
    {
      label: "English",
      content: (
        <Editor                     content={content}
                    setContent={setContent}
                  />
      ),
    },
    {
      label: "French",
      content: (
        <Editor                     content={frenchContent}
                    setContent={setFrenchContent}
                  />
      ),
    },
    {
      label: "Portuguese",
      content: (
        <Editor                     content={portugueseContent}
                    setContent={ setPortugueseContent}
                  />
      ),
    },
  ];

  return (
    <>
       <div className={`w-[90%]] mx-auto shadow-2xl p-4 rounded-lg h-[auto] bg-white `}>
              <header className='flex justify-between items-center'>
                   <div>
                      <h1 className='text-md font-semibold mt-1'>
                        Step 4 <span className='bi bi-arrow-right'></span> &nbsp;
                        Cohort Welcome Message
                      </h1>
                      <p className='text-xs mb-2 text-gray-700'>
                        Welcome page to be displayed after learners have been inivted to a cohort.
                      </p>
                   </div>
              </header>
             <div className='border-t border-gray-300'>
             <div className="my-5">
                  <label className="block font-semibold w-full text-xs mb-1">
                    Would you like to add modules in other languages?
                  </label>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={useMultipleLanguages}
                    onChange={(e) => setUseMultipleLanguages(e.target.checked)}
                  />
                  <label className="text-sm">
                    Yes, I want to add multiple languages
                  </label>
                </div>
                {useMultipleLanguages ? (
                  <HorizontalTab tabsData={tabsData} />
                ) : (
<Editor                     content={content}
                    setContent={setContent}
                  />
                )}
                  

                  <div className='flex mt-5 justify-end'>
                      <Button 
                         width={'w-[160px]'} 
                        text={'Add Welcome Page'}
                        bgColor={'bg-customBlue'}
                        onClick={useMultipleLanguages ? addMultiLanguageWelcomePage : addWelcomePage}
                      />  
                  </div>    
             </div>
         </div>
    </>
  )
}

export default CohortWelcomeMessage