import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import CompanyRoleButton from "../../../../Auth/components/CompanyRoleButton";
import Button from "../../../../../components/Buttons/Button";
import { api } from "../../../../../api/learningPath";
import { api as courseApi } from "../../../../../api/courses";
import { useMutation, useQuery } from "react-query";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import ResourceLoader from "../../courses/pages/CreateCourse/components/ResourceLoader";
import HorizontalTab from "../../../../../components/Tab/HorizontalTab";

const PathInfo = ({
  values,
  setFieldValue,
  setLogoPreview,
  logoPreview,
  step1Validated,
  setIsCreated,
  setActiveScreen,
}) => {
  const [useMultipleLanguages, setUseMultipleLanguages] = useState(false);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();

  const uploadResourceMutation = useMutation(courseApi.createResource, {
    onMutate: () => {
      setIsUploading(true);
    },
    onSuccess: (data) => {
      setIsUploading(false);
      toast.success("File Uploaded successfully!");
      setLogoPreview(data?.data?.data);
    },
  });

  const addPathMutation = useMutation(api.createLearningPath, {
    onSuccess: (data) => {
      // console.log(data);
      sessionStorage.setItem("active-path", JSON.stringify(data?.data));
      toast.remove();
      toast.success(data?.message);
      setIsCreated(true);
      setTimeout(() => {
        setActiveScreen(2);
      }, 100);
    },
    onError: ({ response }) => {
      toast.remove();
      toast.error(response?.data?.message);
    },
  });

  const addPathInfo = () => {
    // console.log(values);

    const startDate = new Date(values.start_date);
    const endDate = new Date(values.end_date);

    if (startDate > endDate) {
      return toast.error(`Start date cannot be after End date`);
    }

    document.querySelector("#create-path-btn").click();
    const formData = new FormData();
    formData.append("name", values.c_title);
    formData.append("description", values.c_des);
    formData.append("coursePoints", values.c_point);
    formData.append("creditPerLearner", values.c_credit);
    formData.append("startDate", values.start_date);
    formData.append("endDate", values.end_date);
    formData.append("type", values.c_type);

    const payload = {
      name: values.c_title,
      description: values.c_des,
      coursePoints: values.c_point,
      creditPerLearner: values.c_credit,
      startDate: values.start_date,
      endDate: values.end_date,
      type: values.c_type,
      status: values?.status ? "published" : "draft",
      imagePath: logoPreview,
      languages: [
        {
          language: "english",
          symbol: "en",
          name: values?.c_title || null,
          description: values?.c_des || null,
          isDefault: true,
        },
      ],
    };
    if (useMultipleLanguages) {
      payload.languages.push(
        {
          language: "french",
          symbol: "fr",
          name: values?.c_title_fr || null,
          description: values?.c_des_fr || null,
          isDefault: false,
        },
        {
          language: "portuguese",
          symbol: "pr",
          name: values?.c_title_pr || null,
          description: values?.c_des_pr || null,
          isDefault: false,
        }
      );
    }
   
    toast.loading("Processing...");
    addPathMutation.mutate({ payload, org_id: user?.org?.id });
  };

  const uploadClientLogo = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      uploadResourceMutation.mutate(formData);
    } else setLogoPreview(null);
  };

  const cancelImagePreview = () => {
    setLogoPreview("");
    // Reset the file input
  };

  const handleStartDateChange = (date) => {
    setFieldValue("start_date", date.toISOString());
  };

  const handleEndDateChange = (date) => {
    setFieldValue("end_date", date.toISOString());
  };

  const handlePublish = () => {
    sessionStorage.removeItem("current_path");
    toast.success("Published successfully...");
    navigate("/admin/learning-path");
  };

  const tabsData = [
    {
      label: `English`,
      content: (
        <div>
          <label className="block font-semibold w-full text-xs mb-1">
            Path Title (English)
          </label>
          <Field name="c_title" className="primary-form-input w-full" />
          <ErrorMessage
            name="c_title"
            className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
            component="p"
          />
          <label className="block font-semibold w-full text-xs mt-5 mb-1">
            Path Description (English)
          </label>
          <Field
            name="c_des"
            as="textarea"
            className="primary-form-input w-full"
          />
          <ErrorMessage
            name="c_des"
            className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
            component="p"
          />
        </div>
      ),
    },
    {
      label: `French`,
      content: (
        <div>
          <label className="block font-semibold w-full text-xs mb-1">
            Path Title (French)
          </label>
          <Field name="c_title_fr" className="primary-form-input w-full" />
          <ErrorMessage
            name="c_title_fr"
            className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
            component="p"
          />
          <label className="block font-semibold w-full text-xs mt-5 mb-1">
            Path Description (French)
          </label>
          <Field
            name="c_des_fr"
            as="textarea"
            className="primary-form-input w-full"
          />
          <ErrorMessage
            name="c_des_fr"
            className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
            component="p"
          />
        </div>
      ),
    },
    {
      label: `Portuguese`,
      content: (
        <div>
          <label className="block font-semibold w-full text-xs mb-1">
            Path Title (Portuguese)
          </label>
          <Field name="c_title_pr" className="primary-form-input w-full" />
          <ErrorMessage
            name="c_title_pr"
            className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
            component="p"
          />
          <label className="block font-semibold w-full text-xs mt-5 mb-1">
            Path Description (Portuguese)
          </label>
          <Field
            name="c_des_pr"
            as="textarea"
            className="primary-form-input w-full"
          />
          <ErrorMessage
            name="c_des_pr"
            className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
            component="p"
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      {" "}
      {isUploading && <ResourceLoader />}
      <h5 className="text-xl mt-5 font-semibold">Learning Path Information</h5>
      <p className="mt-1 text-sm mb-5">
        Tell us more about your learning path.
      </p>
      {/* Checkbox for user to indicate if they want to add course details in other languages */}
      <div className="mb-5">
        <label className="block font-semibold w-full text-xs mb-1">
          Would you like to add learning path details in other languages?
        </label>
        <input
          type="checkbox"
          className="mr-2"
          checked={useMultipleLanguages}
          onChange={(e) => setUseMultipleLanguages(e.target.checked)}
        />
        <label className="text-sm">Yes, I want to add multiple languages</label>
      </div>

      <div className="flex">
        <section className="w-full max-w-[550px]">
          
           {/* Conditionally render based on user's choice */}
           {useMultipleLanguages ? (
              <HorizontalTab tabsData={tabsData} />
            ) : (
              <div>
              <div>
                <label className="block font-semibold w-full text-xs mb-1">
                  Path Title
                </label>
                <Field name="c_title" className="primary-form-input w-full" />
                <ErrorMessage
                  name="c_title"
                  className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
                  component="p"
                />
              </div>
              <div className="mt-5">
                <label className="block font-semibold w-full text-xs mb-1">
                  Path Description
                </label>
                <Field
                  name="c_des"
                  as="textarea"
                  className="primary-form-input w-full"
                />
                <ErrorMessage
                  name="c_des"
                  className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
                  component="p"
                />
              </div>
              </div>
            )}
          
         


          <div className="mt-5 flex hidden justify-between">
            <div className="w-[60%]">
              <label className="block font-semibold w-full text-xs mb-1">
                Credit per learner
              </label>
              <Field
                name="c_credit"
                type="number"
                className="primary-form-input w-full"
              />
              <ErrorMessage
                name="c_credit"
                className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
                component="p"
              />
            </div>
            <div className="w-[35%]">
              <label className="block font-semibold w-full text-xs mb-1">
                Course Point
              </label>
              <Field
                name="c_point"
                type="number"
                className="primary-form-input w-full"
              />
              <ErrorMessage
                name="c_point"
                className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
                component="p"
              />
            </div>
          </div>
          <div className="mt-5 flex items-center justify-start gap-3 w-full">
            {/* <div className="flex-1">
              <label className="block font-semibold w-full text-xs mb-1">
                Type
              </label>
              <Field
                name="c_type"
                as="select"
                className="primary-form-input w-full"
              >
                <option value="learning-path">Learning Path</option>
                <option value="program">Program</option>
                <option value="cohort">Cohort</option>
              </Field>
            </div> */}
            <div>
              <label className="block font-semibold w-full text-xs mb-1">
                Start Date
              </label>
              <ReactDatePicker
                onChange={handleStartDateChange}
                selected={values.start_date}
                dateFormat="dd/MM/yyyy"
                className="primary-form-input w-full"
              />
              <ErrorMessage
                name="start_date"
                className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
                component="p"
              />
            </div>
            <div>
              <label className="block font-semibold w-full text-xs mb-1">
                End Date
              </label>
              <ReactDatePicker
                onChange={handleEndDateChange}
                selected={values.end_date}
                dateFormat="dd/MM/yyyy"
                className="primary-form-input w-full"
              />
              <ErrorMessage
                name="end_date"
                className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
                component="p"
              />
            </div>
            <div className="w-[35%] flex items-center pt-4">
              <CompanyRoleButton
                text={"Publish"}
                height={"h-[30px]"}
                active={values?.status}
                onClick={() => setFieldValue("status", true)}
              />
              &nbsp; &nbsp;
              <CompanyRoleButton
                text={"Draft"}
                height={"h-[30px]"}
                active={!values.status}
                onClick={() => setFieldValue("status", false)}
              />
            </div>
          </div>
        </section>
        <div className="ml-16 mt-5 bg-white w-[200px] h-[180px] border flex items-center justify-center border-gray-200 rounded-lg">
          {logoPreview ? (
            <div className="h-full w-full">
              <img
                src={logoPreview}
                alt="profile"
                className="w-full h-[150px]"
              />
              <button
                onClick={cancelImagePreview}
                className="text-red-500 text-sm block mt-1 w-[auto] mx-auto"
              >
                Cancel
              </button>
            </div>
          ) : (
            <p className="text-sm text-center">
              <input
                type="file"
                id="course-img"
                className="hidden"
                name="image"
                onChange={(event) => {
                  uploadClientLogo(event);
                  setFieldValue("image", event.target.files[0]);
                }}
              />
              <label htmlFor="course-img" className="text-primary mr-1">
                Upload
              </label>{" "}
              an image for your course
            </p>
          )}
        </div>
      </div>
      <div className="w-[300px] mt-4 flex items-center justify-start">
        <Button
          text={"Continue"}
          bgColor={"bg-customBlue"}
          onClick={addPathInfo}
          disabled={!step1Validated}
          style={{ opacity: step1Validated ? 1 : 0.6 }}
        />
      </div>
    </div>
  );
};

export default PathInfo;
