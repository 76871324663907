import createApiClient from "./api";

const client = createApiClient();

export const api = {

  getSports: ({ orgId, search, perPage, currentPage }) =>
    client
      .get(
        `/admin/org/${orgId}/org-sports?perPage=${perPage}${
            search ? `&search=${search}` : ""
          }&page=${currentPage}`
      )
      .then(({ data }) => data),

      createSports: ({ orgId, data }) => client.post(`/admin/org/${orgId}/org-sports`, data)
                                   .then(({ data }) => data),

     updateSports: ({ orgId, data, id }) => client.patch(`/admin/org/${orgId}/org-sports/${id}`, data)
        .then(({ data }) => data),

      getQuiz: ({ orgId, search, perPage, currentPage }) =>
        client
          .get(
            `/admin/org/${orgId}/quizzes?perPage=${perPage}${
                search ? `&search=${search}` : ""
              }&page=${currentPage}`
          )
      .then(({ data }) => data),

      createQuiz: ({ orgId, data }) => client.post(`/admin/org/${orgId}/quizzes`, data)
      .then(({ data }) => data),

      updateQuiz: ({ orgId, data, id }) => client.patch(`/admin/org/${orgId}/quizzes/${id}`, data)
      .then(({ data }) => data),
};
