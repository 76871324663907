import React, { useState } from "react";
import ScreenNavigator from "../components/ScreenNavigator";
import PathInfo from "../components/PathInfo";
import { Form, Formik } from "formik";
import PathContent from "../components/PathContent";
import { useEffect } from "react";

const CreatePath = () => {  
  const [activeScreen, setActiveScreen] = useState(1);
  const [preview, setPreview] = useState(null);
  const [modules, setModules] = useState([]);
  const [pathMediaFiles, setPathMediaFiles] = useState([])
  const [pathPreview, setPathPreview] = useState([])

  const [isCreated,setIsCreated] = useState(false);

  const initialValues = {
    c_title: "",
    c_des: "",
    c_title_fr:"",
    c_des_fr:"",
    c_title_pr:"",
    c_des_pr:"",
    image: "",
    c_point: "1",
    start_date: "",
    end_date: "",
    c_credit: "1",
    c_type: "learning-path",
  };

  const current_path = JSON.parse(sessionStorage.getItem("active-path"));

  useEffect(() => {
    //current_path?.id && setActiveScreen(2);
  },[])

  const validate = (values) => {
    const errors = {};

    // Existing validations
    if (!values?.c_title) errors.c_title = `Path title is required`;
    else if (!values?.c_des) errors.c_des = `Path description is required`;
    else if (!values?.c_title_fr) errors.c_title_fr = `French Path description is required`;
    else if (!values?.c_title_pr) errors.c_title_pr = `Portuguese Path description is required`;
    else if (!values?.c_des_fr) errors.c_des_fr = `French Path description is required`;
    else if (!values?.c_des_pr) errors.c_des_pr = ` Portuguese Path description is required`;
    else if (!values?.c_credit) errors.c_credit = `Credit is required`;
    else if (!values?.c_point) errors.c_point = `Points is required`;
    else if (!values?.start_date) errors.start_date = `Start Date is required`;
    else if (!values?.end_date) errors.end_date = `End Date is required`;
    else if (!values?.c_type) errors.c_type = `Type is required`;

    // Additional validation: Start date cannot be after the end date
    const startDate = new Date(values.start_date);
    const endDate = new Date(values.end_date);

    if (startDate > endDate) {
      errors.start_date = `Start Date cannot be after End Date`;
      errors.end_date = `End Date cannot be before Start Date`;
    }
    return errors;
  };

  const createPath = (values, actions) => {
    actions.setSubmitting(false);
  };

  const step1Validated = (values) =>
    values.c_title &&
    values.c_des &&
    values.c_point &&
    values.c_credit &&
    values.start_date &&
    values.end_date &&
    values.c_type;

  return (
    <div className="bg-lightBlue min-h-[90vh] pl-8 pt-2">
      <h3 className="text-md font-semibold mt-3">&nbsp;Create Learning Path</h3>
      <section>
        <Formik
          initialValues={initialValues}
          validate={validate}
          //  enableReinitialize={true}
          onSubmit={createPath}
        >
          {({ values, setFieldError, setFieldValue, isValid }) => (
            <Form>
              <ScreenNavigator
                activeScreen={activeScreen}
                set1Validated={isCreated}
                setIsCreated={setIsCreated}
                setActiveScreen={setActiveScreen}
                title1={'Path Info'}
                title2={'Path Content'}
              />
              {activeScreen === 1 && (
                <PathInfo
                  values={values}
                  setFieldValue={setFieldValue}
                  logoPreview={preview}
                  setLogoPreview={setPreview}
                  step1Validated={step1Validated(values)}
                  setActiveScreen={setActiveScreen}
                  setIsCreated={setIsCreated}
                />
              )}
              {activeScreen === 2 && (
                <PathContent
                  activeScreen={activeScreen}
                  setActiveScreen={setActiveScreen}
                  values={values}
                  setFieldValue={setFieldValue}
                  modules={modules}
                  setModules={setModules}
                  pathMediaFiles={pathMediaFiles}
                  setPathMediaFiles={setPathMediaFiles}
                />
              )}
              <button
                type="submit"
                id="create-path-btn"
                className={`hidden`}
                disabled={validate}
              ></button>
            </Form>
          )}
        </Formik>
      </section>
    </div>
  );
};

export default CreatePath;
