import React, { useState } from "react";
import Switch from "react-switch";
import Button from "../../../../../../../../../components/Buttons/Button";
import { useMutation } from "react-query";
import { api } from "../../../../../../../../../api/learningPath";
import toast from "react-hot-toast";
import { errorResponse } from "../../../../../../../../../components/utils/errorResponse";
import HorizontalTab from "../../../../../../../../../components/Tab/HorizontalTab";

const CreateCohort = ({
  cohortName,
  setCohortName,
  status,
  setStatus,
  setCurrentStep,
  setEnableTeam,
  setCohortId,
  cohortId,
}) => {
  const [useMultipleLanguages, setUseMultipleLanguages] = useState(false);
  const orgId = JSON.parse(sessionStorage.getItem("user"))?.org?.id;
  const [frenchCohortName, setFrenchCohortName] = useState("");
  const [portugueseCohortName, setPortugueseCohortName] = useState("");
  const activePathId = JSON.parse(sessionStorage.getItem("active-path"))?.id;

  const createCohortMutation = useMutation(
    cohortId ? api.updateCohort : api.createCohort,
    {
      onSuccess: (response) => {
        toast.remove();
        toast.success(
          `Cohort ${cohortId ? "Updated" : "Created"} Successfully.`
        );
        setCohortId(response?.data?.id);
        if (!status) setCurrentStep(2);
        else setEnableTeam(true);
      },
      onMutate: () => {
        toast.loading("Loading...");
      },
      onError: (error) => errorResponse(error),
    }
  );

  const addCohortMutlilanguageNameMutation = useMutation(api.addMultiLanguageName,
    {
      onSuccess: (response) => {
        toast.remove();
        toast.success(
          `Cohort Created Successfully.`
        );
        setCohortId(response?.data?.id);
        if (!status) setCurrentStep(2);
        else setEnableTeam(true);
      },
      onMutate: () => {
        toast.loading("Loading...");
      },
      onError: (error) => errorResponse(error),
    }
  );

  const createCohort = () => {
    if (!cohortName) toast.error("Error! Cohort Name Is Required");
    else {
      const payload = {
        id: orgId,
        data: {
          name: cohortName,          
        },
      };     
      
      createCohortMutation.mutate(
        cohortId ? { ...payload, cohortId } : payload
      );
    }
  };

 
  const addMultiLanguageName = () => {
    if (!cohortName) {
      toast.error("Error! Cohort Name Is Required");
    } else {
      if (!cohortId) {
        // Ensure cohortId exists before proceeding
        toast.error("Error! Cohort ID is not available");
        return;
      }
  
      const payload = {
        id: orgId,
        cohortId, // Make sure cohortId is defined
        data: {
          languages: [
            {
              language: "english",
              symbol: "en",
              name: cohortName || null,
              isDefault: true,
            },
            {
              language: "french",
              symbol: "fr",
              name: frenchCohortName || null,
              isDefault: false,
            },
            {
              language: "portuguese",
              symbol: "pr",
              name: portugueseCohortName || null,
              isDefault: false,
            }
          ],
        },
      };
  
      
      addCohortMutlilanguageNameMutation.mutate(payload);
    }
  };
  

  const tabsData = [
    {
      label: `English`,
      content: (
        <div>
          <label className="text-xs"> Cohort Name (English)</label>
          <input
            className="input-md mt-1"
            placeholder="Cohort Name...."
            onChange={(e) => setCohortName(e.target.value)}
            value={cohortName}
          />
        </div>
      ),
    },
    {
      label: `French`,
      content: (
        <div>
          <label className="text-xs"> Cohort Name (French)</label>
          <input
            className="input-md mt-1"
            placeholder="Cohort Name...."
            onChange={(e) => setFrenchCohortName(e.target.value)}
            value={frenchCohortName}
          />
        </div>
      ),
    },
    {
      label: `Portuguese`,
      content: (
        <div>
          <label className="text-xs"> Cohort Name (Portuguese)</label>
          <input
            className="input-md mt-1"
            placeholder="Cohort Name...."
            onChange={(e) => setPortugueseCohortName(e.target.value)}
            value={portugueseCohortName}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div
        className={`w-[500px] mx-auto shadow-2xl p-4 rounded-lg h-[auto] bg-white mt-8`}
      >
        <header className="flex justify-between items-center">
          <div>
            <h1 className="text-md font-semibold mt-1">
              Step 1 <span className="bi bi-arrow-right"></span> &nbsp;Create
              new cohort
            </h1>
            <p className="text-xs mb-2 text-gray-700">
              enter your cohort details
            </p>
          </div>
        </header>
        <div className="border-t border-gray-300 mt-3 pt-3">
          {/* Checkbox for user to indicate if they want to add course details in other languages */}
          <div className="mb-5">
            <label className="block font-semibold w-full text-xs mb-1">
              Would you like to add cohort name in other languages?
            </label>
            <input
              type="checkbox"
              className="mr-2"
              checked={useMultipleLanguages}
              onChange={(e) => setUseMultipleLanguages(e.target.checked)}
            />
            <label className="text-sm">
              Yes, I want to add multiple languages
            </label>
          </div>
          {useMultipleLanguages ? (
            <HorizontalTab tabsData={tabsData} />
          ) : (
            <div>
              <label className="text-xs"> Cohort Name </label>
              <input
                className="input-md mt-1"
                placeholder="Cohort Name...."
                onChange={(e) => setCohortName(e.target.value)}
                value={cohortName}
              />
            </div>
          )}
          <div className="mt-5 text-xs flex items-center">
            Enable Teams &nbsp;&nbsp;&nbsp;&nbsp;
            <Switch
              //value={enableAffiliate}
              height={20}
              width={40}
              handleDiameter={18}
              checked={status}
              onChange={() => setStatus(!status)}
            />
          </div>
          <div className="flex mt-5 justify-end">
            <Button
              width={"w-[160px]"}
              text={"Create Cohort"}
              bgColor={"bg-customBlue"}
                           onClick={useMultipleLanguages ? addMultiLanguageName : createCohort}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCohort;
