import React from 'react'
import { Link } from 'react-router-dom'

const Settings = () => {
  return (
    <div className='bg-lightBlue -mt-3 px-12 h-[100vh]'>
       <h5 className='text-lg font-semibold mt-3 pt-5'> Settings </h5>
       <section className='grid mt-5 grid-cols-3 gap-8'>
           <Link className='bg-white p-8 text-center rounded-lg shadow-md'
              to={'/admin/settings/feedback'}
            >
                <span className='text-center text-5xl bi bi-headset text-secondary'></span>
                <p className='text-center mt-2 text-md'>
                    Feedback
                </p>
           </Link>
           <Link className='bg-white p-8 text-center rounded-lg shadow-md'
              to={'/admin/email'}
            >
                <span className='text-center text-5xl bi bi-envelope-at text-secondary'></span>
                <p className='text-center mt-2 text-md'>
                    Email Editor
                </p>
           </Link>
           <Link className='bg-white p-8 text-center rounded-lg shadow-md'
              to={'/admin/certificate'}
            >
                <span className='text-center text-5xl bi bi-patch-check text-secondary'></span>
                <p className='text-center mt-2 text-md'>
                    Certificate Editor
                </p>
           </Link>
           <Link className='bg-white p-8 text-center rounded-lg shadow-md'
              to={'/admin/'}
            >
                <span className='text-center text-5xl bi bi-menu-button text-secondary'></span>
                <p className='text-center mt-2 text-md'>
                    Branding
                </p>
           </Link>
           <Link className='bg-white p-8 text-center rounded-lg shadow-md'
              to={'/admin/settings/zoom/api'}
            >
                <span className='text-center text-5xl bi bi-camera-reels text-secondary'></span>
                <p className='text-center mt-2 text-md'>
                    Zoom Integration
                </p>
           </Link>
       </section>
    </div>
  )
}

export default Settings