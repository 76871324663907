import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import CompanyRoleButton from "../../../../../../Auth/components/CompanyRoleButton";
import Button from "../../../../../../../components/Buttons/Button";
import { api } from "../../../../../../../api/courses";
import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast";
import ResourceLoader from "./ResourceLoader";
import HorizontalTab from "../../../../../../../components/Tab/HorizontalTab";

const CourseInfo = ({
  values,
  setFieldValue,
  setLogoPreview,
  logoPreview,
  step1Validated,
  setActiveScreen,
  setCourseId,
  setCompletedStep,
}) => {
    // const { values, setFieldValue } = useFormikContext();
  const [isUploading, setIsUploading] = useState(false);
  const [useMultipleLanguages, setUseMultipleLanguages] = useState(false); // Track if user wants to use multiple languages

  const uploadResourceMutation = useMutation(api.createResource, {
    onMutate: () => {
      setIsUploading(true);
    },
    onSuccess: (data) => {
      setIsUploading(false);
      toast.success("File Uploaded successfully!");
      setLogoPreview(data?.data?.data);
    },
  });

  const { data: categories, isLoading } = useQuery(
    ["get categories"],
    () => {
      return api.getCategories({ perPage: 10000 });
    },
    {
      cacheTime: 10000,
      staleTime: 10000,
      refetchOnWindowFocus: true,
      select: (data) => data?.data,
    }
  );

  const addCourseMutation = useMutation(api.createCourse, {
    onSuccess: (data) => {
      toast.remove();
      toast.success("Course Create successfully!");
      setActiveScreen(2);
      setCompletedStep(1);
      setCourseId(data?.data?.id);
    },
    onError: (error) => {
      toast.remove();
      //toast.success('Course Create successfully!')
      //setActiveScreen(2)
      if (error.response.status === 500) toast.error(error.response.statusText);
      else if (error.response.status === 400)
        toast.error(error?.response?.data?.message);
      else {
        if (error?.response?.data?.errors?.length > 1) {
          error?.response?.data?.errors?.map((error) => {
            toast?.error(error?.message);
          });
        } else toast.error(error?.response?.data?.errors[0]?.message);
      }
    },
    onMutate: () => {
      toast.loading("Loading...");
    },
  });

  const addCourseInfo = () => {
    //console.log(values);
    document.querySelector("#create-course-btn").click();
    const formData = new FormData();
    /*formData.append('name', values.c_title);
      formData.append('description', values.c_des);
      formData.append('categoryId', values.c_category);
      formData.append('points', values.c_point);
      formData.append('price', values.c_price);
      formData.append('status', 'published');
      formData.append('duration', 500);
      formData.append('imagePath', '/');*/
    const payload = {
  
      categoryId: values.c_category,
      points: values.c_point,
      price: values.c_price,
      status: values?.status ? "published" : "draft",
      duration: 500,
      imagePath: logoPreview,
      moduleOrder: "set",
      image: "aiui",
      isBranded: false,
      repeatModule: false,
      languages: [
        {
          language: "english",
          symbol: "en",
          name: values?.c_title_en || null , 
          description: values?.c_des_en || null , 
          isDefault: true,
        },
        
      ],
    };
    if (useMultipleLanguages) {
        payload.languages.push({
            language: "french",
            symbol: "fr",
            name: values?.c_title_fr || null, 
            description: values?.c_des_fr || null,
            isDefault: false,
          },
          {
            language: "portuguese",
            symbol: "pr",
            name: values?.c_title_pr || null, 
            description: values?.c_des_pr || null, 
            isDefault: false,
          },);
      }

    //formData.append('image', values?.image);
    //formData.append('isBranded', values?.status ? 1 : 0 );
    toast.loading("Processing...");
    addCourseMutation.mutate(payload);
  };

  const uploadClientLogo = (e) => {
    const file = e.target.files[0];
    if (file) {
      /*const reader = new FileReader();
      reader.onload = () => {
         setLogoPreview(reader.result)
      };
      reader.readAsDataURL(file);*/
      const formData = new FormData();
      formData.append("file", file);
      uploadResourceMutation.mutate(formData);
    } else setLogoPreview(null);
  };

  const cancelImagePreview = () => {
    setLogoPreview("");
    // Reset the file input
  };

  const tabsData = [
    {
        label: `English`,
        content: (
            <div>
                <label className='block font-semibold w-full text-xs mb-1'>
                    Course Title (English)
                </label>
                <Field
                    name='c_title_en'
                    className='primary-form-input w-full'
                />
                <ErrorMessage
                    name='c_title_en'
                    className='text-xs mt-2 ml-2 text-red-500 font-semibold w-full'
                    component='p'
                />
                <label className='block font-semibold w-full text-xs mt-5 mb-1'>
                    Course Description (English)
                </label>
                <Field
                    name='c_des_en'
                    as='textarea'
                    className='primary-form-input w-full'
                />
                <ErrorMessage
                    name='c_des_en'
                    className='text-xs mt-2 ml-2 text-red-500 font-semibold w-full'
                    component='p'
                />
            </div>
        ),
    },
    {
        label: `French`,
        content: (
            <div>
                <label className='block font-semibold w-full text-xs mb-1'>
                    Course Title (French)
                </label>
                <Field
                    name='c_title_fr'
                    className='primary-form-input w-full'
                />
                <ErrorMessage
                    name='c_title_fr'
                    className='text-xs mt-2 ml-2 text-red-500 font-semibold w-full'
                    component='p'
                />
                <label className='block font-semibold w-full text-xs mt-5 mb-1'>
                    Course Description (French)
                </label>
                <Field
                    name='c_des_fr'
                    as='textarea'
                    className='primary-form-input w-full'
                />
                <ErrorMessage
                    name='c_des_fr'
                    className='text-xs mt-2 ml-2 text-red-500 font-semibold w-full'
                    component='p'
                />
            </div>
        ),
    },
    {
        label: `Portuguese`,
        content: (
            <div>
                <label className='block font-semibold w-full text-xs mb-1'>
                    Course Title (Portuguese)
                </label>
                <Field
                    name='c_title_pr'
                    className='primary-form-input w-full'
                />
                <ErrorMessage
                    name='c_title_pr'
                    className='text-xs mt-2 ml-2 text-red-500 font-semibold w-full'
                    component='p'
                />
                <label className='block font-semibold w-full text-xs mt-5 mb-1'>
                    Course Description (Portuguese)
                </label>
                <Field
                    name='c_des_pr'
                    as='textarea'
                    className='primary-form-input w-full'
                />
                <ErrorMessage
                    name='c_des_pr'
                    className='text-xs mt-2 ml-2 text-red-500 font-semibold w-full'
                    component='p'
                />
            </div>
        ),
    },
];

  return (
    <div>
      {" "}
      {isUploading && <ResourceLoader />}
      <h5 className="text-xl mt-5 font-semibold">Course Information</h5>
      <p className="mt-1 text-sm mb-5">Tell us more about your course.</p>
      <div className="flex">
        <section className="w-[500px]">
          <div>
            {/* Checkbox for user to indicate if they want to add course details in other languages */}
            <div className="mb-5">
              <label className="block font-semibold w-full text-xs mb-1">
                Would you like to add course details in other languages?
              </label>
              <input
                type="checkbox"
                className="mr-2"
                checked={useMultipleLanguages}
                onChange={(e) => setUseMultipleLanguages(e.target.checked)}
              />
              <label className="text-sm">
                Yes, I want to add multiple languages
              </label>
            </div>

            {/* Conditionally render based on user's choice */}
            {useMultipleLanguages ? (
              <HorizontalTab tabsData={tabsData} />
            ) : (
              <div>
                <div>
                  <label className="block font-semibold w-full text-xs mb-1">
                    Course Title
                  </label>
                  <Field name="c_title_en" className="primary-form-input w-full" />
                  <ErrorMessage
                    name="c_title_en"
                    className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
                    component="p"
                  />
                </div>
                <div className="mt-5">
                  <label className="block font-semibold w-full text-xs mb-1">
                    Course Description
                  </label>
                  <Field
                    name="c_des_en"
                    as="textarea"
                    className="primary-form-input w-full"
                  />
                  <ErrorMessage
                    name="c_des_en"
                    className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
                    component="p"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="mt-5 flex justify-between">
            <div className="w-full">
              <label className="block font-semibold w-full text-xs mb-1">
                Course Category
              </label>
              <Field
                name="c_category"
                as="select"
                className="primary-form-input w-full"
              >
                {isLoading ? (
                  <option value=""> Loading.. </option>
                ) : categories?.length ? (
                  <>
                    <option value=""> Select Category </option>
                    {categories?.map((category) => {
                      return (
                        <option key={category?.id} value={category?.id}>
                          {category?.name}
                        </option>
                      );
                    })}
                  </>
                ) : (
                  <option value=""> No any category found </option>
                )}
              </Field>
              <ErrorMessage
                name="c_category"
                className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
                component="p"
              />
            </div>
            <div className="w-[35%] hidden">
              <label className="block font-semibold w-full text-xs mb-1">
                Course Point
              </label>
              <Field
                name="c_point"
                type="number"
                className="primary-form-input w-full"
              />
              <ErrorMessage
                name="c_point"
                className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
                component="p"
              />
            </div>
          </div>
          <div className="mt-5 flex justify-between">
            <div className="w-[60%] hidden">
              <label className="block hidden font-semibold w-full text-xs mb-1">
                Course Price (₦)
              </label>
              <Field
                name="c_price"
                type="number"
                className="primary-form-input w-full"
              />
              <ErrorMessage
                name="c_price"
                className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
                component="p"
              />
            </div>
            <div className="w-[35%] flex items-center pt-4">
              <CompanyRoleButton
                text={"Publish"}
                height={"h-[30px]"}
                active={values?.status}
                onClick={() => setFieldValue("status", true)}
              />
              &nbsp; &nbsp;
              <CompanyRoleButton
                text={"Draft"}
                height={"h-[30px]"}
                active={!values.status}
                onClick={() => setFieldValue("status", false)}
              />
            </div>
          </div>
        </section>
        <div className="ml-16 mt-5 bg-white w-[200px] h-[180px] border flex items-center justify-center border-gray-200 rounded-lg">
          {logoPreview ? (
            <div className="h-full w-full">
              <img
                src={logoPreview}
                alt="profile"
                className="w-full h-[150px]"
              />
              <button
                onClick={cancelImagePreview}
                className="text-red-500 text-sm block mt-1 w-[auto] mx-auto"
              >
                Cancel
              </button>
            </div>
          ) : (
            <p className="text-sm text-center">
              <input
                type="file"
                id="course-img"
                className="hidden"
                name="image"
                onChange={(event) => {
                  uploadClientLogo(event);
                  setFieldValue("image", event.target.files[0]);
                }}
              />
              <label htmlFor="course-img" className="text-primary mr-1">
                Upload
              </label>{" "}
              an image for your course
            </p>
          )}
        </div>
      </div>
      <div className="w-[300px] mt-4">
        <Button
          text={"Continue"}
          bgColor={"bg-customBlue"}
          onClick={addCourseInfo}
          style={{ opacity: step1Validated ? 1 : 0.6 }}
        />
      </div>
    </div>
  );
};

export default CourseInfo;
