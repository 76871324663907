import React from "react";
import Analytics from "./Analytics";
import LearnersTable from "./components/LearnersTable";
import { LearnersProvider } from "../../../../context/learnersContext";
import { Routes, Route } from "react-router";
import TeamsRoute from "./teams/TeamsRoute";
import ActionPlanDashboard from "./ActionPlan/ActionPlanDashboard";
import QuizRoutes from './quizAndSports/Routes'

const Learners = () => {
  return (
    <LearnersProvider>
      <div className="bg-lightBlue p-5">
        <Routes>
          <Route path="/" element={<LearnersTable />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/teams/*" element={<TeamsRoute />} />
          <Route path="/quiz-and-sports/*" element={<QuizRoutes />} />
          <Route path="/action-plan" element={<ActionPlanDashboard />} />
        </Routes>
      </div>
    </LearnersProvider>
  );
};

export default Learners;
