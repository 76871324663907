import React, { useEffect, useState } from 'react'
import Button from '../../../../../../../../components/Buttons/Button'
import Loader from '../../../../../../../../components/loader/Loader';
import FeedbackTable from './components/FeedbackTable';
import Pagination from '../../../../../../../../components/pagination/Pagination';
import { useQuery } from 'react-query';
import { feedbackApi } from '../../../../../../../../api/feedback';
import Search from '../../../../../../../../components/Inputs/Search';
import ViewQuestionsModal from './components/ViewQuestionsModal';
import CustomizeFeedbackModal from './components/CustomizeFeedbackModal';

const ViewTemplates = () => {

 const [perPage, setPerPage] = useState(5);
 const [search, setSearch] = useState("");
 const [currentPage, setCurrentPage] = useState(1);
 const key = "get feedback templates"
 const [activeTemplate, setActiveTemplate] = useState(null)
 const [openQModal, setOpenQModal] = useState(false)
 const [ openCustomizeFeedbackModal, setOpenCustomizeFeedbackModal ] = useState(false)
 const [isCourse, setIsCourse] = useState(true)
  const [afterCourses, setAfterCourses] = useState(true)
  const [isMandatory, setIsMandatory] = useState(true)
  const [afterAssessment, setAfterAssessment]= useState(false)
  const [journeyId, setJourneyId] = useState(null)
  const [courseId, setCourseId] = useState(null)

 const { data: templates, isLoading, refetch } = useQuery(
    [key, { perPage, currentPage, search }],
    ({queryKey}) => { 
        return feedbackApi.getFeedbackTemplates(queryKey[1]);
    },
    {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
  });

  useEffect( () => {
    refetch({ queryKey: [ key, { perPage, currentPage, search }] })
  }, [ currentPage, perPage, ])
  
 return (
    <div className='p-3 pt-2 bg-lightBlue'>
         <header className='flex py-2 bg-white px-2 items-center justify-between mt-3'>
            <h5 className='text-md font-semibold'>
                Feedback Summary   
            </h5>
             <Button  
                bgColor={'bg-customBlue'}
                text={'Create Feedback Template'}
                width={'w-[170px]'} 
                renderAs='link'
                to='/admin/settings/feedback/create-feedback-template'
            />
        </header> 
        <ViewQuestionsModal 
          activeTemplate={activeTemplate} open={openQModal} setOpen={setOpenQModal} 
        />
        <CustomizeFeedbackModal 
           activeTemplate={activeTemplate}
           open={openCustomizeFeedbackModal}
           setOpen={setOpenCustomizeFeedbackModal}
           refetch={refetch}
           isCourse={isCourse}
            setIsCourse={setIsCourse}
            afterCourses={afterCourses}
            setAfterCourses={setAfterCourses}
            afterAssessment={afterAssessment}
            setAfterAssessment={setAfterAssessment}
            isMandatory={isMandatory}
            setIsMandatory={setIsMandatory}
            courseId={courseId}
            setCourseId={setCourseId}
            journeyId={journeyId}
            setJourneyId={setJourneyId}
        />
        <section className='bg-white mt-5 rounded-lg p-3 '>
         <header className='flex justify-between items-center border-b border-gray-200'>
            <h5 className='text-sm font-semibold pb-3 py-2'>
                Total Templates ({templates?.meta?.total})
            </h5>
            <Search 
              width={'w-[300px]'} placeholder={'Search template'}
              onChange={ event =>  {
                setSearch(event.target.value)
                refetch({ queryKey: [ key, { perPage, currentPage, search: event.target.value }] })
              }}  
            />
         </header>
            { isLoading ? <Loader /> : 
              <FeedbackTable 
                  isLoading={isLoading} templates={templates}
                  setOpenCustomizeFeedbackModal={setOpenCustomizeFeedbackModal}
                  perPage={perPage}  refetch={refetch}
                  setActiveTemplate={setActiveTemplate}
                  activeTemplate={activeTemplate}
                  setOpenQModal={setOpenQModal}
                  openQModal={openQModal}
                  setAfterAssessment={setAfterAssessment}
                  setCourseId={setCourseId}
                  setAfterCourses={setAfterCourses}
                  setIsCourse={setIsCourse}
                  setIsMandatory={setIsMandatory}
                  setJourneyId={setJourneyId}
              />
            }
        </section>
        <section className='bg-white mt-2 px-2'>
          <Pagination 
              data={templates?.meta}
              perPage={perPage}
              pageNo={currentPage}
              setPageNo={setCurrentPage}
              setPerPage={setPerPage}
           />
        </section>
    </div>
  )
}

export default ViewTemplates