import React, { useEffect, useState } from 'react'
import Button from '../../../../../../../../components/Buttons/Button'
import { useMutation, useQuery } from 'react-query';
import { feedbackApi } from '../../../../../../../../api/feedback';
import toast from 'react-hot-toast';
import { errorResponse } from '../../../../../../../../components/utils/errorResponse';
import Pagination from '../../../../../../../../components/pagination/Pagination';
import Search from '../../../../../../../../components/Inputs/Search';
import EmptyData from '../../../../../../../../components/EmptyData';
import Loader from '../../../../../../../../components/loader/Loader';
import { useNavigate } from 'react-router-dom';
import ReorderList from 'react-reorder-list';

const EditFeedback = () => {

    const template = JSON.parse(sessionStorage.getItem('active-template'))
    const [perPage, setPerPage] = useState(5);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [templeteName, setTemplateName] = useState(template?.name)
    const [selectedQuestions, setSelctedQuestions] = useState( 
        template?.questions?.length ? 
          template?.questions?.map( q => q?.question )
        : []
    )
    const navigate = useNavigate()

    const { data: questionBank, isLoading, refetch } = useQuery(
        ["get question bank", { perPage, currentPage, search }],
        ({queryKey}) => { 
            return feedbackApi.getQuestions({...queryKey[1]});
        },
        {
            cacheTime: 100,
            staleTime: 100,
            refetchOnWindowFocus: true,
            refetchOnMount: true,
      });

      const updateTemplateMutation = useMutation(feedbackApi.editTemplate, {
        onSuccess: res => {
            toast.remove()
            toast.success(res?.message)
            navigate('/admin/settings/feedback')
            sessionStorage.removeItem('active-template')
         },
         onMutate: () => {
            toast.loading('Loading...')
         },
         onError: error => errorResponse(error)
      })

      useEffect( () => {
        refetch({ queryKey: ["get question bank", { perPage, currentPage, search }] })
      }, [ currentPage, perPage, isLoading] )

      const addQuestions = (question) => {
        if(selectedQuestions.length){
           const questionExist = selectedQuestions.findIndex( q => q?.id === question?.id ) !== -1
          if(questionExist) setSelctedQuestions( selectedQuestions?.filter( q => q?.id !== question?.id ) )
          else setSelctedQuestions([...selectedQuestions, question])
        }
        else setSelctedQuestions([question])
     }
   
     const updateFeedback = () => {
        if(!templeteName) toast.error('Error! template name is required.')
        else if(!selectedQuestions.length) toast.error('Error! no question selected')
        else {
          const payload = {
             id: template?.id,
             data: {
                name: templeteName,
                questionIds: selectedQuestions?.map( question => question?.id )
             }
          }
         updateTemplateMutation.mutate(payload)
       }
     }

     const deleteQuestion = id => {
        setSelctedQuestions( selectedQuestions?.filter( q => q?.id !== id ) )
     }
 
  return (
    <div className='bg-lightBlue p-3'>
        <header className='flex justify-between pt-3'>
            <h5 className='text-md'>
               Edit Feedback Template   
            </h5>
            <div className='flex'>
                    <Button 
                        bgColor={'bg-white shadow mr-3'}
                        text={'Cancel'}
                        width={'w-[150px]'}
                        textColor={'text-secondary'}
                        renderAs='link'
                        to='/admin/settings/feedback'
                    /> &nbsp;&nbsp;&nbsp;
                     <Button 
                        bgColor={'bg-customBlue'}
                        text={'Edit Template'}
                        width={'w-[150px]'} 
                        onClick={updateFeedback}
                    /> 
              </div>
        </header>
        <div className='flex justify-between'>
            <section className='border w-[49%] bg-white mt-5 border-gray-200 p-3 rounded-lg shadow'>
                <div>
                    <label className='text-sm mb-1'>Template Name</label>
                    <input
                        type="text" className='input-md shadow mt-1'
                        placeholder='Feedback Template Title...'   
                        value={templeteName}
                        onChange={ e => setTemplateName(e.target.value) }  
                    />
                </div>
                <section className='mt-3'>
                    <h5 className='text-sm mb-3'>Questions ({selectedQuestions.length})</h5>
                    <div>
                    { selectedQuestions?.length ?
                    <ReorderList  onPositionChange={ ({newItems})  => {
                           const updatedQuestions = newItems?.map( item => {
                              return item?.props?.question
                           })
                           setSelctedQuestions(updatedQuestions)
                        }}
                        watchChildrenUpdates={true}
                        >
                           {
                              selectedQuestions.length ? 
                                selectedQuestions?.map( question => {
                                 return (
                                  <div question={question} key={question?.id} className='flex hover:cursor-pointer justify-between p-3 border border-gray-200 mb-3 rounded shadow items-center'>
                                      <span className='text-sm font-light'>
                                          {question?.text}
                                      </span>
                                      <span 
                                        className='bi bi-trash text-red-500 hover:cursor-pointer text-md ml-2'
                                        onClick={ () => deleteQuestion(question?.id)}
                                      />
                                  </div>                                            
                                 )
                              } )
                           : null
                           }
                           </ReorderList>
                           : <EmptyData text={'No question selected.'} />
                        }
                    </div>
                </section>
            </section>
            <section className='border bg-white w-[49%] mt-5 border-gray-200 p-3 rounded-lg shadow'>
                <div className='flex items-center justify-between mb-3'>
                    <h3 className='text-sm mb-1'>Question Bank ({questionBank?.meta?.total})</h3>
                    <Button 
                        bgColor={'bg-customBlue'}
                        text={'Create New Questions'}
                        width={'w-[170px]'}
                        renderAs='link'
                        to='/admin/settings/feedback/create-feedback?redirect=edit'
                    />
                </div>
                <Search 
                  placeholder={'Search question bank'} 
                  onChange={ event => {
                     setSearch(event.target.value)
                     refetch(["get question bank", { perPage, currentPage, search: event.target.value }])
                  }}
                 />
                <section className='mt-3'>
                    {
                     isLoading ? <Loader /> :
                     questionBank?.data?.length ? 
                      questionBank?.data?.map( question => {
                        return (
                            <div className='flex p-3 border border-gray-200 mb-3 rounded shadow items-center'>
                                <div>
                                    <input 
                                      type="checkbox" className='w-[20px] h-[20px] mr-2'
                                      onClick={ () => addQuestions(question) }
                                      checked={selectedQuestions.findIndex( q => q?.id === question?.id ) !== -1}
                                   />
                                </div>
                               <label className='text-sm font-light'>
                                  {question?.text}
                               </label>
                            </div>
                        )
                      })
                    : null}
                </section>
                <Pagination 
                    data={questionBank?.meta}
                    perPage={perPage}
                    pageNo={currentPage}
                    setPageNo={setCurrentPage}
                    setPerPage={setPerPage}
                />
            </section>
        </div>
    </div>
  )
}

export default EditFeedback