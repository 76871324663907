import React, { useEffect, useState } from 'react'
import Button from '../../../../../../../components/Buttons/Button'
import OverviewCard from './../../../../report-and-analysis/components/OverviewCard';
import BadgeIcon from '../../../../../../../assets/Badge.png'
import BadgeIconOrange from '../../../../../../../assets/Bagde (5).png'
import BadgeIconGreen from '../../../../../../../assets/Badge (1).png'
import Search from '../../../../../../../components/Inputs/Search';
import { api } from '../../../../../../../api/teams';
import { useQuery } from 'react-query';
import EmptyData from '../../../../../../../components/EmptyData';
import TeamsTable from './components/TeamsTable';
import Pagination from '../../../../../../../components/pagination/Pagination';
import Loader from '../../../../../../../components/loader/Loader';

const ViewTeams = () => {

  const [perPage, setPerPage] = useState(8);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id

  const {
    data: teams,
    isLoading,
    refetch,
  } = useQuery(
    ["view teams"],
    ({ queryKey }) => {
      return api.getTeams({ ...queryKey[1], perPage, currentPage, search, orgId });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      refetchOnMount:false,
      select: (data) => data,
    })

    const {
      data: teamSummary,
      isLoading: summaryLoading,
    } = useQuery(
      ["team summary"],
      ({ queryKey }) => {
        return api.getTeamSummary({ ...queryKey[1], perPage, currentPage, search, orgId });
      },
      {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: true,
        refetchOnMount:false,
        select: (data) => data?.data,
      })

    useEffect( () => {
        refetch()
    }, [ currentPage, perPage, search, isLoading, setSearch ] )

  return (
    <div>
        <header className='flex justify-between'>
             <h2>Teams Overview</h2>
             <Button 
               renderAs='link' 
               to='/admin/learners/teams/create' 
               text={'Export'}
               width={'w-[130px]'}
               bgColor={'bg-customBlue'}
             />
        </header>  
        <section className='mt-5 grid grid-cols-5 gap-5'>
           <OverviewCard
             iconPath={BadgeIcon}
             title={'Total Learners'}
             total={summaryLoading ? '...' : teamSummary?.numberOfLearners}
           />
           <OverviewCard
             iconPath={BadgeIconOrange}
             title={'Active Learners'}
             total={summaryLoading ? '...' : teamSummary?.numberOfActiveLearners}
           />
           <OverviewCard
             iconPath={BadgeIconGreen}
             title={'Completion Rate'}
             total={summaryLoading ? '...' :`${teamSummary?.percentageOfCompletedLearners || 0}%`}
           />
           <OverviewCard
             iconPath={BadgeIcon}
             title={'In-progress'}
             total={summaryLoading ? '...' : teamSummary?.numberOfLearnersInProgress}
           />
           <OverviewCard
             iconPath={BadgeIconOrange}
             title={'Enrolement Rate'}
             total={ summaryLoading ? '...' : `${teamSummary?.percentageOfEnrolledLearners}%`}
           />
        </section>
        <section className='px-3 pt-3 pb-1 flex  items-center justify-between mt-5 rounded-lg bg-white'>
             <div className='w-[300px]'>
                <Search 
                   className={'mb-0'} placeholder={'Search Teams'}
                   onChange={ event => setSearch(event.target.value) }
                />
            </div>  
            <div className='flex items-center'>
                <button className='border mr-5 text-sm bi bi-filter-square border-gray-200 py-2 px-3 rounded-lg'>
                    &nbsp; Filters
                </button>
                <Button 
                  renderAs='link' 
                  to='/admin/learners/teams/create' 
                  text={'Create Team'}
                  width={'w-[130px]'}
                  bgColor={'bg-customBlue'}
                />
            </div>
        </section>
        <section className='mt-5 bg-white p-3'>
            {
              isLoading ? <Loader /> :
              teams?.data?.length ? <TeamsTable teams={teams} isLoading={isLoading} /> :
              <EmptyData text={'There is any team available yet.'} />
              
            }
        </section>
        <section className='bg-white mt-2 px-2'>
          <Pagination 
              data={teams?.meta}
              perPage={perPage}
              pageNo={currentPage}
              setPageNo={setCurrentPage}
              setPerPage={setPerPage}
           />
        </section>
    </div>
  )
}

export default ViewTeams