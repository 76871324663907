import React, { useState } from "react";
import { Formik, Form } from "formik";
import { FormBuilder, Flex, Submit, Toggler, Time } from "./Components";
import "./toggle.css";
import useModule from "../../../../hooks/useModules";
import UploadModule from "./UploadModule";
import HorizontalTab from "../../../../components/Tab/HorizontalTab";

const CreateModule = () => {
  const [useMultipleLanguages, setUseMultipleLanguages] = useState(false);

  const {
    assessmentTypeProps,
    formProps,
    fileProps,
    filePathProps,
    timeProps,
  } = useModule(null, useMultipleLanguages); // Pass the useMultipleLanguages state to useModule

  return (
    <div className="w-full">
      <Formik {...formProps}>
        {({ values, setFieldValue, setFieldError }) => {
          const tabsData = [
            {
              label: "English",
              content: (
                <div>
                  <FormBuilder
                    fieldTitle="name"
                    fieldName="name" // Match the validation schema
                    label="Name"
                    fieldType="text"
                  />
                  <FormBuilder
                    fieldTitle="description"
                    fieldName="description" // Match the validation schema
                    label="Description"
                    fieldType="textarea"
                  />
                  <Flex>
                    <Time {...timeProps} />
                    <FormBuilder
                      fieldTitle="sorting"
                      fieldName="sorting" // Match the validation schema
                      fieldType="number"
                    />
                  </Flex>
                  <Flex>
                    <FormBuilder
                      fieldTitle="points"
                      fieldName="points" // Match the validation schema
                      fieldType="number"
                    />
                  </Flex>
                  <Flex>
                    <UploadModule
                      fileProps={fileProps}
                      setFieldError={setFieldError}
                      setFieldValue={setFieldValue}
                    />
                    <FormBuilder readOnly {...filePathProps} />
                  </Flex>
                </div>
              ),
            },
            {
              label: "French",
              content: (
                <div>
                  <FormBuilder
                    fieldTitle="name__french"
                    fieldName="name__french" // Match the validation schema
                    label="Name"
                    fieldType="text"
                  />
                  <FormBuilder
                    fieldTitle="description__french"
                    fieldName="description__french" // Match the validation schema
                    label="Description"
                    fieldType="textarea"
                  />
                  <Flex>
                    <UploadModule
                      fileProps={fileProps}
                      setFieldError={setFieldError}
                      setFieldValue={setFieldValue}
                    />
                    <FormBuilder readOnly {...filePathProps} />
                  </Flex>
                </div>
              ),
            },
          ];

          return (
            <Form>
              <Flex>
                <Toggler
                  title="Assessment?"
                  value={values.isAssessment}
                  onChange={() =>
                    setFieldValue("isAssessment", !values.isAssessment)
                  }
                />
              </Flex>

              {values.isAssessment && <FormBuilder {...assessmentTypeProps} />}

              <div>
                <div className="mb-5">
                  <label className="block font-semibold w-full text-xs mb-1">
                    Would you like to add modules in other languages?
                  </label>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={useMultipleLanguages}
                    onChange={(e) => setUseMultipleLanguages(e.target.checked)}
                  />
                  <label className="text-sm">
                    Yes, I want to add multiple languages
                  </label>
                </div>

                {useMultipleLanguages ? (
                  <HorizontalTab tabsData={tabsData} />
                ) : (
                  <div>
                    <FormBuilder
                      fieldTitle="name"
                      fieldName="name" // Match the validation schema
                      fieldType="text"
                    />
                    <FormBuilder
                      fieldTitle="description"
                      fieldName="description" // Match the validation schema
                      fieldType="textarea"
                    />
                    <Flex>
                      <Time {...timeProps} />
                      <FormBuilder
                        fieldTitle="sorting"
                        fieldName="sorting" // Match the validation schema
                        fieldType="number"
                      />
                    </Flex>
                    <Flex>
                      <FormBuilder
                        fieldTitle="points"
                        fieldName="points" // Match the validation schema
                        fieldType="number"
                      />
                    </Flex>
                    <Flex>
                      <UploadModule
                        fileProps={fileProps}
                        setFieldError={setFieldError}
                        setFieldValue={setFieldValue}
                      />
                      <FormBuilder readOnly {...filePathProps} />
                    </Flex>
                  </div>
                )}
              </div>

              <Flex>
                <Submit />
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateModule;
