import React, { useEffect, useState } from 'react'
import DataChart from './DataChart'
import { useMutation, useQuery } from 'react-query'
import { learners } from '../../../../../api/learners'
import { dashboardApi } from '../../../../../api/dashboard'
import EmptyData from '../../../../../components/EmptyData'
import Loader from '../../../../../components/loader/Loader'
import CourseCompletionRatePerWeekChart from './CourseCompletionRatePerWeekChart'
import { api } from '../../../../../api/learningPath'
import AverageAttemptPerCourseChart from './AverageAttemptPerCourseChart'

const CourseCompletionChart = ({cohorts, clientId, isClient}) => {
 
  const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id
  const cohortIds = ( cohorts?.length >= 2 ) ? cohorts?.map( (cohort) => cohort?.value ).join(',') : ''
  const views = ['Course Completion Per Week','Cohort Completion Rate Per Week', 'Learners Completion Per Course']
  const [view, setView] = useState(views[0])
  const [journeyId, setJourneyId] = useState(null)
  const [cohortId, setCohortId] = useState(null)
  const [filters, setFilters] = useState([])
  const [courseCompletionRateJourney, setCourseCompletionRateJourney] = useState(null)

  const { data: journeysList, isLoading: pathLoading } = useQuery(
    ["get journeys list"],
    () => {
      if(isClient) return api.getLearningPath({ perPage: 10000, currentPage:1, org_id: orgId, });
      else return clientId && api.getLearningPath({ 
          perPage: 10000, currentPage:1, org_id: clientId 
        });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      refetchOnMount:true,
      select: (data) => data?.data,
      onSuccess: data => {
        setCourseCompletionRateJourney(data?.length ? data[0]?.id : null)
      }
    })

  const filtersMutation = useMutation(learners.getFilters, {
    onSuccess: res => {
       let response = res?.data
       if(response?.length){
        if(!cohortId) setCohortId(response[0]?.cohortId);
        if(response?.length) {
          setJourneyId(response[0]?.learningPaths?.length ?
              response[0]?.learningPaths[0]?.learningPathId : null
          )
        }
       }
       setFilters(response)
    }
  })

  useEffect( () => {
    if(isClient) filtersMutation.mutate(orgId);
  }, [])

  useEffect( () => {
    if(clientId && !isClient) filtersMutation.mutate(clientId);
  }, [clientId])

  const courseCompletionKey = "client dashboard course completion"
  const { data: chartData, isLoading: courseCompletionLoading, refetch,} = useQuery(
    [courseCompletionKey, {cohort: cohortId, journey: journeyId, }],
    ({queryKey}) => { 
        return (cohortId && journeyId) && dashboardApi.getCourseCompletionByWeek(queryKey[1]);
    },
    {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        retry: false,
        select: response => response?.courseData,
  });

  const cohortCompletionKey = "client dashboard cohort completion by weeks"
  const { 
      data: cohortCompletionChartData,
      isLoading: cohortCompletionLoading, 
      refetch: refetchCohortCompletion
    } = useQuery(
    [cohortCompletionKey, {cohorts: cohortIds, journey: courseCompletionRateJourney, }],
    ({queryKey}) => { 
        return cohortIds && dashboardApi.getCourseCompletionRateByWeek(queryKey[1]);
    },
    {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        retry: false,
        select: response => response?.result,
  });

  const averageAttempsKey = "client dashboard average attemps"
  const { 
      data: averageAttempsChartData,
      isLoading: averageAttempsLoading, 
      refetch: refetchAverageAttemps
    } = useQuery(
    [averageAttempsKey, {cohorts: cohortIds, journey: courseCompletionRateJourney, }],
    ({queryKey}) => { 
        return cohortIds && dashboardApi.getAverageAttemps(queryKey[1]);
    },
    {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        retry: false,
        select: response => response?.result,
  });

  const journeys = filters?.find( cohort => cohort?.cohortId === cohortId )?.learningPaths

  useEffect( () => {
    refetch({queryKey: [ courseCompletionKey, { cohort: cohortId, journey: journeyId } ]})
  }, [cohortId,journeyId])

  useEffect( () => {
    refetchCohortCompletion({ 
        queryKey: [ cohortCompletionKey, { cohorts: cohortIds, journey: courseCompletionRateJourney } ]
    })
    refetchAverageAttemps(
    {queryKey: [averageAttempsKey, {cohorts: cohortIds, journey: courseCompletionRateJourney, }]}
    )
  }, [cohortIds, courseCompletionRateJourney])

  return (
    <div className='w-full overflow-x-hidden'>  
        <h5 className='font-bold flex justify-between border-b border-gray-300 pb-3'> 
             <div className='w-[350px]'>  
                <select
                    value={view}
                    onChange={ event => setView(event.target.value) }
                    className="py-[11.5px] bg-lightBlue outline-none focus:ring-0 flex-1 text-xs rounded-md shadow-md px-2"
                >
                {views?.map(( currentView ) => (
                    <option key={currentView} value={currentView}>
                    {currentView}
                    </option>
                ))}
                </select>
             </div>
            { view === views[0] &&
            <div className='grid grid-cols-2 gap-5 w-[400px]'>
               <select
                 value={cohortId}
                 onChange={ event => {
                     const journeys = filters?.find( cohort => cohort?.cohortId === event.target.value )?.learningPaths
                     setCohortId(event.target.value)
                     setJourneyId( journeys?.length ? journeys[0]?.learningPathId :  null)
                 }}
                 className="py-[11.5px] bg-lightBlue outline-none focus:ring-0 flex-1 text-xs rounded-md shadow-md px-2"
               >  
                { !filtersMutation.isLoading ? 
                    filters?.map((cohort) => (
                        <option key={ cohort?.cohortName } value={ cohort?.cohortId }>
                          { cohort?.cohortName }
                        </option>
                    ))
                   : <option value="">Loading...</option>
                }
               </select>
               <select
                 value={journeyId}
                 onChange={ event => setJourneyId(event.target.value) }
                 className="py-[11.5px] bg-lightBlue text-xs outline-none focus:ring-0 flex-1 rounded-md shadow-md px-2"
               >
                    { 
                      journeys?.length ?
                       journeys.map(( path ) => (
                        <option key={path?.learningPathName} value={path?.learningPathId}>
                           {path?.learningPathName}
                        </option>
                    )) : <option>Not Found</option>}
               </select>
            </div>
            }
            {
                (view === views[1] || view === views[2]) && 
                <div className='w-[300px]'>
                 <select
                  value={courseCompletionRateJourney}
                  onChange={ event => {
                      setCourseCompletionRateJourney(event.target.value)
                 }}
                 className="py-[11.5px] w-full bg-lightBlue outline-none focus:ring-0 flex-1 text-xs rounded-md shadow-md px-2"
               >
                { !pathLoading ? 
                    journeysList?.map((journey) => (
                        <option key={ journey?.name } value={ journey?.id }>
                          { journey?.name }
                        </option>
                    ))
                   : <option value="">Loading...</option>
                }
                </select>
            </div>
            }
        </h5>
        {
          view === views[0] ?
          courseCompletionLoading ?
           <Loader /> :
          (cohortId && journeyId) ? 
             <DataChart chartData={chartData} />
          :
           <EmptyData text={'Select Cohort and Learning Path To View Data'} />
        : null}
        {
          view === views[1] ?
             cohortCompletionLoading ? 
              <Loader /> :
               <CourseCompletionRatePerWeekChart chartData={cohortCompletionChartData} />
          : null
        }
        {
            view === views[2] ?
                <AverageAttemptPerCourseChart chartData={averageAttempsChartData} />
            : null
        }
    </div>
  )
}

export default CourseCompletionChart