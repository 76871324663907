/* eslint-disable no-restricted-globals */
import { useMemo, useState, useEffect } from "react";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import { api } from "../api/courses";
import toast from "react-hot-toast";
import { convertDate, convertTime } from "../pages/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

function timeToSeconds(hours, minutes, seconds) {
  // Convert hours, minutes, and seconds to seconds
  return hours * 3600 + minutes * 60 + seconds;
}


const useModule = (type, useMultipleLanguages) => {
 
  const currentModule =    JSON.parse(sessionStorage.getItem("currentModule")) || {};
  const { mutateAsync: CreateModule } = useMutation(api.createModule);
  const { mutateAsync: EditModule } = useMutation(api.updateModule);
  const [modal, showModal] = useState(false);
  const [editModal, showEditModal] = useState(false);
  const [perPage, setPerPage] = useState(6);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const handleModal = () => {
    showModal(!modal);
  };

  const handleEditModal = () => {
    showEditModal(!editModal);
  };

  const { data, refetch, isLoading } = useQuery(
    ["Modules", { currentPage, search, perPage }],
    ({ queryKey }) => {
      return api.getModules({ ...queryKey[1] });
    },
    {
      cacheTime: 10000,
      staleTime: 10000,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  useEffect(() => {
    refetch();
  }, [search, perPage, currentPage]);

  const handleEdit = (item) => {
    return () => {
      toast.loading("Processing");
      console.log(item, "item")
      sessionStorage.setItem("currentModule", JSON.stringify(item));
      setTimeout(() => {
        toast.remove();
        handleEditModal();
      }, 2000);
    };
  };

  const handleDelete = (item) => {
    return () => {
      //console.log(item);
    };
  };

  let columns = useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) => index + 1,
      },
      {
        title: "Name",
        selector: "name",
        key: "name",
      },
      {
        title: "Description",
        selector: "description",
        key: "description",
      },
      {
        title: "Duration",
        selector: "duration",
        key: "duration",
        render: ({ item }) => {
          return <span>{convertTime(item)}</span>;
        },
      },
      {
        title: "Assessment Type",
        selector: "assessmentType",
        key: "assessmentType",
        render: ({ item }) => {
          const options = [
            { title: "Pre-assessment", value: "pre" },
            { title: "Post-assessment", value: "post" },
            { title: "Both", value: "pre and post" },
            { title: "None", value: "none" },
          ];

          const option = options.find((option) => option.value === item);

          // Check if option is found
          const titleToRender = option ? option.title : "-";
          return <span>{titleToRender}</span>;
        },
      },
      {
        title: "Date Created",
        selector: "createdAt",
        key: "createdAt",
        render: ({ item }) => {
          return <span>{convertDate(item)}</span>;
        },
      },
      {
        title: "Actions",
        selector: null,
        key: null,
        render: ({ row }) => {
          return (
            <div className="flex items-center justify-start gap-2">
              <button
                className="text-blue-500 text-lg"
                onClick={handleEdit(row)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
              {/*<button className="text-red-500" onClick={handleDelete(row)}>
                <FontAwesomeIcon icon={faTrash} />
              </button>*/}
            </div>
          );
        },
      },
    ],
    []
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    sorting: Yup.number().required("Sorting is required"),
    points: Yup.number().required("Points are required"),
    text: Yup.string().required("File is required"),
    ...(useMultipleLanguages && {
      name__french: Yup.string().required("French Name is required"),
      description__french: Yup.string().required("French Description is required"),
      text__french: Yup.string().required("French File is required"),
    }),
  });


  function extractPath(
    url,
    baseUrl = "https://qwizmobi.s3.amazonaws.com/ssh-test/"
  ) {
    if (url.startsWith(baseUrl)) {
      return url.substring(baseUrl?.length);
    } else {
      return null;
    }
  }

  const initialValues = {
    name: type === "edit" ? currentModule?.name : "",
    description: type === "edit" ? currentModule?.description : "",
    name__french: type === "edit" ? currentModule?.languages[1]?.name : "",
    description__french:
      type === "edit" ? currentModule?.languages[1]?.description : "",

    hours:
      type === "edit"
        ? Math.floor(parseInt(currentModule?.duration) / (60 * 60))
        : "",
    minutes:
      type === "edit"
        ? Math.floor((parseInt(currentModule?.duration) % 3600) / 60)
        : "",
    seconds:
      type === "edit"
        ? Math.floor((parseInt(currentModule?.duration) % 3600) / (60 * 60))
        : "",
    sorting: type === "edit" ? currentModule?.sorting || 0 : 0,
    points: type === "edit" ? currentModule?.points || 0 : 0,
    isAssessment: type === "edit" ? currentModule?.isAssessment : false,
    assessmentType:
      type === "edit" ? currentModule?.assessmentType || "pre" : "pre",
    file: "",
    text:
      type === "edit"
        ? currentModule?.filePath
        : "",
    text__french:
      type === "edit"
        ? currentModule?.languages[1]?.filePath
        : "",

        // courses/avenir_1727906903189/story.html
    // text__portugues: type === "edit" ? currentModule?.filePath : ''
    // name__portuguese: type === "edit" ? currentModule?.name__portuguese : "",
    // description__portuguese: type === "edit" ? currentModule?.description_portuguese : "",
  };

  const onSubmit = async (values) => {
    //payload for creating a module
    const payload = {
      assessmentType: values.assessmentType,
      // name: values.name,
      // description:values.description,
      filePath: extractPath(values?.text)
        ? extractPath(values?.text)
        : values?.text,
      duration: timeToSeconds(values.hours, values.minutes, values.seconds),
      sorting: values.sorting,
      isAssessment: values.isAssessment,
      points: values.points,
      languages: [
        {
          language: "English",
          symbol: "en",
          name: values.name,
          description: values.description,
          filePath: extractPath(values?.text)
            ? extractPath(values?.text)
            : values?.text,
          isDefault: true,
        },
        
      ],
    };
    // Only include the French language if multi-language is enabled
  if (useMultipleLanguages) {
    payload.languages.push({
      language: "French",
      name: values.name__french || "",
      description: values.description__french || "",
      filePath: values.text__french || "",
      isDefault: false,
    });
  }
    toast.loading("Processing...");  
    

    //payload for editing module
    // const editModulePayload = {
    //   id: currentModule?.id,
    //   data: {
    //     // name: values.name,
    //     // description: values?.description,
    //     duration: timeToSeconds(values.hours, values.minutes, values.seconds),
    //     sorting: values?.sorting,
    //     points: values?.points,
    //     isAssessment: values?.isAssessment,
    //     assessmentType: values?.assessmentType,
    //     filePath: extractPath(values?.text)
    //       ? extractPath(values?.text)
    //       : values?.text,
    //     languages: [
    //       {
    //         language: "English",
    //         symbol: "en",
    //         name: values.name || null,
    //         description: values.description || null,
    //         filePath: extractPath(values?.text)
    //           ? extractPath(values?.text)
    //           : values?.text || null,
    //         isDefault: true,
    //       },
    //       {
    //         language: "french",
    //         symbol: "fr",
    //         name: values.name__french || null,
    //         description: values.description__french || null,
    //         filePath: extractPath(values?.text__french)
    //           ? extractPath(values?.text__french)
    //           : values?.text__french || null,
    //         isDefault: false,
    //       },
    //     ],
    //   },
    // };


    try {
      let data;
      // if (type === "edit") data = await EditModule(editModulePayload);
      // else data = await CreateModule(payload);
      if (type === "edit") {
        data = await EditModule({ id: currentModule.id, data: payload });
      } else {
        data = await CreateModule(payload);
      }
      toast.remove();
      toast.success(data?.message);
      // sessionStorage.removeItem("currentModule");
      setTimeout(() => {
        refetch();
        document.querySelector("#close-modal-btn").click();
      }, 100);
    } catch (error) {
      toast.remove();
      if (error.response.status === 500) toast.error(error.response.statusText);
      else if (
        error.response.status === 400 &&
        !error?.response?.data?.errors?.length
      ) {
        toast.error(error?.response?.data?.message);
      } else {
        if (error?.response?.data?.errors?.length > 1) {
          error?.response?.data?.errors?.map((error) => {
            toast?.error(error?.message);
          });
        } else toast.error(error?.response?.data?.errors[0]?.message);
      }
    }
  };

  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
  };

  const fileProps = {
    label: "Upload Modules Zip File",
    fieldTitle: "file",
    fieldType: "file",
  };

  const filePathProps = {
    label: "File Path",
    fieldTitle: "text",
    fieldType: "text",
  };

  const timeProps = {
    label: "Duration",
    hours: "hours",
    minutes: "minutes",
    seconds: "seconds",
  };

  const assessmentTypeProps = {
    fieldTitle: "assessmentType",
    label: "Assessment Type",
    fieldType: "select",
    options: [
      { title: "Pre-assessment", value: "pre" },
      { title: "Post-assessment", value: "post" },
      { title: "Both", value: "pre and post" },
      { title: "None", value: "none" },
    ],
  };

  const buttonProps = {
    text: "Create module",
    width: "w-[150px]",
    bgColor: "bg-darkBlue",
    onClick: handleModal,
  };

  const tableProps = { data: data?.data, isLoading, columns };

  const createModuleProps = {
    open: modal,
    setOpen: handleModal,
  };

  const editModuleProps = {
    open: editModal,
    setOpen: handleEditModal,
  };

  return {
    data,
    refetch,
    isLoading,
    columns,
    assessmentTypeProps,
    formProps,
    fileProps,
    filePathProps,
    timeProps,
    buttonProps,
    tableProps,
    modal,
    handleModal,
    editModal,
    handleEditModal,
    createModuleProps,
    editModuleProps,
    currentModule,
    perPage,
    setPerPage,
    currentPage,
    setCurrentPage,
    setSearch,
  };
};

export default useModule;
