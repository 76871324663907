import React, { useState } from "react";
import Header from "../../components/Layout/Header/Header";
import FormHeader from "./components/FormHeader";
import Ellipse3 from "../../assets/Ellipse 3.png";
import Ellipse1 from "../../assets/Ellipse 1 (1).png";
import Input from "./components/Input";
import Button from "../../components/Buttons/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { loginValidation } from "./utils/validation";
import { useMutation } from "react-query";
import { api } from "../../api";
import toast from "react-hot-toast";

const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const location = useLocation();
  const isAdminRoute = location.pathname.includes("admin");
  const navigate = useNavigate();

  const validate = (values) => loginValidation(values);

  const loginMutation = useMutation(api.login, {
    onSuccess: (response) => {
      // console.log(response);
      toast.remove();
      toast.success("Logged in successfully!");
      sessionStorage.setItem("user", JSON.stringify(response));
      // if (!isAdminRoute)
      navigate("/admin/dashboard",);
    },
    onMutate: () => {
      toast.loading("Loading...");
    },
    onError: ({ response }) => {
      toast.remove();
      toast.error(response.data?.message);
    },
  });

  const login = (values, actions) => {
    // Handle submission

    loginMutation.mutate({
      email: values?.email,
      password: values?.password,
      accountType: 'admin',
    });
    actions.setSubmitting(false);
  };

  return (
    <div>
      <Header isAdminRoute={isAdminRoute} />
      <div
        className={`mt-12 relative w-[90%] mx-auto shadow-lg rounded-lg md:w-[450px] ${
          isAdminRoute ? "bg-white" : "bg-lightOrange"
        } py-5 px-8`}
      >
        <img
          src={Ellipse3}
          alt="ellipse"
          className="w-[70px] h-[40px] top-0 right-0 absolute"
        />
        <FormHeader
          text="Welcome Back to Service School House"
          className={`${
            isAdminRoute ? "text-customBlue" : "text-primary"
          } font-bold text-2xl mt-8`}
        />
        <Formik
          initialValues={initialValues}
          validate={validate}
          enableReinitialize={true}
          onSubmit={login}
        >
          {({ values, setFieldError, setFieldValue }) => (
            <Form>
              <Input
                label={"Email/Username"}
                name={"email"}
                type="email"
                colorScheme={isAdminRoute ? "blue" : "orange"}
              />
              <Input
                label={"Password"}
                name={"password"}
                type="password"
                visible={passwordVisible}
                setVisible={setPasswordVisible}
                showError={isAdminRoute ? true : false}
                colorScheme={isAdminRoute ? "blue" : "orange"}
              />
              <div className="mt-8">
                <button
                  type="submit"
                  id="submit-btn"
                  className={`w-[80%] block mx-auto ${
                    isAdminRoute ? "bg-customBlue" : "bg-primary"
                  } text-white text-center py-3 rounded-lg text-xs`}
                >
                  Continue
                </button>
                <p className="mt-5 text-xs pl-8">
                  {" "}
                  Forgot Password? &nbsp;
                  <Link
                    className={`text-xs ${
                      isAdminRoute
                        ? "underline text-customBlue"
                        : "text-primary"
                    } font-semibold`}
                    to="/auth/forgot-password"
                  >
                    Reset Password
                  </Link>
                </p>
              </div>
              <img
                src={Ellipse1}
                alt="ellipse"
                className="w-[70px] h-[40px] bottom-[1px] left-0 absolute"
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
