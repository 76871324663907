import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";

import Input from "../../../../Auth/components/Input";
import { api } from "../../../../../api/zoom";
import toast from "react-hot-toast";
import SingleSelectDropdown from "../../../components/SingleSelectDropdown";
import ReactDatePicker from "react-datepicker";

const meetingOptions = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];
const Options = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

const EditMeeting = ({ nextStep }) => {
  const navigate = useNavigate();
  const { meetingId } = useParams(); // Assuming your route is configured to capture the ID
  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;

  // Get a single meeting details
  const { data: meeting } = useQuery(
    ["meeting"],
    async () => {
      const response = await api.getMeeting({ orgId, meetingId });
      console.log(response.data, "respondse");
      return response.data;
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [selectTimeZone, setSelectTimezone] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [meetingType, setMeetingType] = useState(
    meeting?.zoomMeetingDetails?.settings?.private_meeting || true
  );
  const [startDate, setStartDate] = useState(
    meeting?.meeting?.startTime || new Date()
  );

  const handlePrivateMeeting = (option) => {
    setMeetingType(option.value);
  };

  const handleSelectedActivity = (option) => {
    setIsRecording(option.value);
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    const dateTimeString = date.toISOString().split(".")[0];
    setDateTime(dateTimeString);

    // Extract the timezone string
    const timeZoneString = date.toString().match(/GMT[^\s]*/)[0];
    setSelectTimezone(timeZoneString);
  };

  const validationSchema = Yup.object().shape({
    topic: Yup.string().required("meeting topic is Required"),
    summary: Yup.string().required("meeting description is Required"),
    duration: Yup.string().required("duration is Required"),
    password: Yup.string().required("password is Required"),
  });

  const initialValues = {
    topic: meeting?.meeting?.topic || "",
    summary: meeting?.zoomMeetingDetails?.agenda || "",
    duration: meeting?.meeting?.duration || "",
    password: meeting?.meeting?.password || "",
  };

  const handleNext = (values) => {
        const extendedValues = {
      ...values, // Existing form values
      meetingType, // Boolean for meeting type
      dateTime, // Date and time in ISO format
      selectTimeZone, // Time zone string
      isRecording,
      meeting, // The original meeting object
      meetingId,
    };
    nextStep(extendedValues); // Pass extended values to the next step
  };

  return (
    <div className="p-10">
      <div className="mb-6">
        <h1 className="text-[31px] font-bold text-[#1D1F2C]">
          Update Zoom Meeting{" "}
        </h1>
      </div>
      <div className="w-[400px]">
        <h3 className="text-lg font-semibold text-[#091F46]">
          STEP 1: Set Up Meeting
        </h3>
        <p className="font-medium text-[#4A4C56] text-base">
          Visit your Zoom account and fetch the API ket to connect Zoom.
          <Link
            to="https://developers.zoom.us/docs/internal-apps/create/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="text-[#007AFF]">Go to Zoom website</span>
          </Link>
        </p>
      </div>
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          console.log("Formik form is submitting"); // Log form submission
          handleNext(values);
        }}
      >
        {({ values, setFieldError, setFieldValue }) => (

          <Form>
            <div className="mt-8">
              <div className="mb-3 flex gap-6 items-center">
                <label
                  htmlFor="type"
                  className="text-[#333] mb-2 text-base font-medium"
                >
                  Meeting Name
                  {/* <span className="text-red-500 required-dot">*</span> */}
                </label>
                <Field
                  type="text"
                  id="topic"
                  className={`rounded-lg   appearance-none border 
                    w-1/2 py-2 px-3 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary/80 focus:border-transparent`}
                  name="topic"
                  placeholder="Enter meeting topic"
                />
                <ErrorMessage
                  name="topic"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="mb-3 flex gap-6 items-center">
                <label
                  htmlFor="as"
                  className="text-[#333] mb-2 text-base font-medium"
                >
                  Meeting Summary
                  {/* <span className="text-red-500 required-dot">*</span> */}
                </label>
                <Field
                  as="textarea"
                  id="summary"
                  className={`rounded-lg   appearance-none border 
                    w-1/2 py-2 px-3 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary/80 focus:border-transparent`}
                  name="summary"
                  placeholder="Enter meeting description"
                />
                <ErrorMessage
                  name="summary"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="w-fit flex gap-x-14 items-center mb-11">
              <Input
                label={"Duration"}
                name={"duration"}
                type="number"
                colorScheme={"orange"}
              />
              <div className="flex flex-col mt-4">
                <p className="text-xs font-normal mb-1 ">Date and Time </p>

                <ReactDatePicker
                  selected={startDate}
                  onChange={handleDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeCaption="time"
                  className="border p-2 rounded border-gray-300 focus:primary focus:ring-0 focus:ring-offset-2" // Tailwind styling
                />
              </div>
              <Input
                label={"Password"}
                name={"password"}
                type="password"
                visible={passwordVisible}
                setVisible={setPasswordVisible}
                colorScheme={"orange"}
              />
              {/* <Input
              label={"Meeting Date"}
              name={"meetingDate"}
              type="text"
              colorScheme={"orange"}
            />
            <Input
              label={"Meeting Time"}
              name={"meetingTime"}
              type="text"
              colorScheme={"orange"}
            /> */}

              {/* <Input
              label={"Minutes"}
              name={"minutes"}
              type="text"
              colorScheme={"orange"}
            /> */}
            </div>
            <div className="w-full flex gap-x-14 items-center mb-11">
              <div className="flex flex-col w-fit">
                <p className="text-xs 2xl:texts-sm  font-normal">
                  Private Meeting
                </p>
                <SingleSelectDropdown
                  options={meetingOptions}
                  onSelect={handlePrivateMeeting}
                  placeholder="private meeting"
                  placeholderColor="#A5A5A5"
                />
              </div>

              <div className="flex flex-col w-fit">
                <p className="text-xs 2xl:texts-sm font-normal">
                  Enable Recording
                </p>
                <SingleSelectDropdown
                  options={Options}
                  onSelect={handleSelectedActivity}
                  placeholder="Select storage"
                  placeholderColor="#A5A5A5"
                />
              </div>
            </div>
            <div className="flex gap-x-96 items-center">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className={`font-semibold 
                     bg-primary
                   text-white py-3 px-8 rounded-lg text-xs`}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={` font-semibold 
                      bg-customBlue
                   text-white py-3 px-8 rounded-lg text-xs`}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditMeeting;
